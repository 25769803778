import FetchHelper from "./FetchHelper";
import AuthManager from "./AuthManager";

export default class Backend {
  static getStats() {
    let url = window.Api.Stats + "?website_id=" + AuthManager.currentWebsite.id;
    return FetchHelper.get(url);
  }

  static updatePage(page) {
    let data = {
      title: page.title,
      slug: page.slug,
      is_live: page.is_live,
      keywords: page.keywords,
      description: page.description,
    };

    return FetchHelper.patch(`${window.Api.Pages}/${page.id}`, data);
  }

  static addPage(data) {
    data.website = AuthManager.currentWebsite.id;
    return FetchHelper.post(`${window.Api.Pages}`, data);
  }

  static deletePage(page) {
    return FetchHelper.delete(`${window.Api.Pages}/${page.id}`);
  }

  static updateCompanyMember(companyMember) {
    let data = {
      role: companyMember.role,
      user: {
        first_name: companyMember.user.first_name,
        last_name: companyMember.user.last_name,
        email: companyMember.user.email,
      },
      notify_daily_stat_summary: companyMember.notify_daily_stat_summary,
      notify_sale: companyMember.notify_sale,
      notify_submission: companyMember.notify_submission,
      notify_message: companyMember.notify_message
    };

    if (companyMember.user.password) {
      data.user.password = companyMember.user.password;
    }

    return FetchHelper.patch(
      `${window.Api.Staff}/${companyMember.user.id}`,
      data
    );
  }

  static addCompanyMember(companyMemberData) {
    let data = {
      role: companyMemberData.role,
      ...companyMemberData.user,
    };
    return FetchHelper.post(`${window.Api.Staff}`, data);
  }

  static deleteCompanyMember(companyMember) {
    return FetchHelper.delete(`${window.Api.Staff}/${companyMember.user.id}`);
  }

  static createInvite(data) {
    data.website = AuthManager.currentWebsite.id;
    return FetchHelper.post(window.Api.Invites, data);
  }

  static uploadInvites(file) {
    let formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("website_id", AuthManager.currentWebsite.id);

    return FetchHelper.post(window.Api.Invites, formData, true);
  }

  static exportInvites() {
    let url =
      window.Api.Invites + "?website_id=" + AuthManager.currentWebsite.id;
    url += "&export=true";

    return new Promise((resolve, reject) => {
      var req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "blob";
      req.setRequestHeader(
        "Authorization",
        "Bearer " + AuthManager.accessToken
      );
      req.onload = function (event) {
        var blob = req.response;
        var fileName = "export.csv";
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        resolve();
      };

      req.send();
    });
  }

  static uploadFile(file, endpoint, params) {
    let data = new FormData();

    let filesEndpoint = window.Api.Files;

    data.append("file", file, file.name);
    for (var key in params) {
      data.append(key, params[key]);
    }

    if (filesEndpoint) {
      data.append("website", AuthManager.currentWebsite.id);
    }

    return FetchHelper.post(endpoint, data, true);
  }

  static removeFile(fileId) {
    return FetchHelper.delete(`${window.Api.Files}/${fileId}`);
  }

  static uploadImage(image, type = "photo") {
    let data = new FormData();

    data.append("file", image, image.name);
    data.append("type", type);

    return FetchHelper.post(window.Api.Images, data, true);
  }

  static addImage(data, validateTokens = true) {
    data.type = "photo";
    return FetchHelper.post(window.Api.Images, data, false, validateTokens);
  }

  static updateWebsite(website, data = null) {
    data = data || {
      name: website.name,
      status: website.status,
      favicon_id: website.favicon?.id,
    };

    return FetchHelper.patch(`${window.Api.Websites}/${website.slug}`, data);
  }

  static getDNSRecords(website) {
    let url =
      window.Api.DNSRecords + "?website_id=" + AuthManager.currentWebsite.id;
    return FetchHelper.get(url);
  }

  static getMXRecords(website) {
    let url =
      window.Api.MXRecords + "?website_id=" + AuthManager.currentWebsite.id;
    return FetchHelper.get(url);
  }

  static getShop(website) {
    let url = window.Api.Shops + "/" + website.id;
    return FetchHelper.get(url);
  }

  static updateShop(shop, data) {
    return FetchHelper.patch(`${window.Api.Shops}/${shop.website.id}`, data);
  }

  static createShop(data) {
    data.website = AuthManager.currentWebsite.id;
    return FetchHelper.post(`${window.Api.Shops}`, data);
  }

  static getConnectData() {
    return FetchHelper.get(
      `${window.Api.Connect}?website_id=${AuthManager.currentWebsite.id}`
    );
  }

  static updateConnect(data) {
    data = JSON.parse(JSON.stringify(data));
    data.website = AuthManager.currentWebsite.id;

    data.representative = this._getPersoonData(data.representative);
    if (data.business_type === "individual") {
      delete data.representative.id;
    }

    for (var i = 0; i < data.persons; i++) {
      data.persons[i] = this._getPersoonData(data.persons[i]);
    }

    return FetchHelper.post(window.Api.Connect, data);
  }

  static _getPersoonData(data) {
    let person = { ...data };
    let { verification } = person;

    if (!verification) {
      return data;
    }

    if (verification.document) {
      if (!verification.document.front_updated) {
        delete person.verification.document.front;
      }
      if (!verification.document.back_updated) {
        delete person.verification.document.back;
      }
    }
    if (verification.additional_document) {
      if (!verification.additional_document.front_updated) {
        delete person.verification.additional_document.front;
      }
      if (!verification.additional_document.back_updated) {
        delete person.verification.additional_document.back;
      }
    }
    delete verification.document.front_updated;
    delete verification.document.back_updated;
    delete verification.additional_document.front_updated;
    delete verification.additional_document.back_updated;

    return person;
  }

  static getTags(searchTerm) {
    return FetchHelper.get(`${window.Api.Tags}?search_term=${searchTerm}`).then(
      (response) => {
        return response.results.map((tag) => tag.name);
      }
    );
  }

  static getProductBySlug(slug, shopId) {
    return FetchHelper.get(`${window.Api.Products}/${slug}?shop_id=${shopId}`);
  }

  static createProduct(data) {
    let productData = Backend._getProductData(data);
    return FetchHelper.post(window.Api.Products, productData);
  }

  static editProduct(data) {
    let productData = Backend._getProductData(data);
    return FetchHelper.patch(`${window.Api.Products}/${data.id}`, productData);
  }

  static _getProductData(product) {
    let data = { ...product };

    data.featured_image = data.featured_image.id;
    data.image_ids = data.images.map((image) => image.id);
    data.related_products = data.related_products.map((product) => product.id);
    data.categories = data.categories.map((category) => category.id);
    data.variants = data.variants.map((variant) => {
      return {
        ...variant,
        image_ids: variant.images.map((image) => image.id),
      };
    });

    return data;
  }

  static getProductCategories(shop) {
    let websiteId = shop ? shop.website_id : AuthManager.currentWebsite.id;
    return FetchHelper.get(
      `${window.Api.ProductCategories}?shop_id=${websiteId}&paginated=false`
    );
  }

  static addProductCategory(data) {
    return FetchHelper.post(window.Api.ProductCategories, data);
  }

  static getAccountLink() {
    return FetchHelper.get(`${window.Api.Connect}`);
  }

  static saveBankAccount(data) {
    return FetchHelper.post(window.Api.BankAccounts, data);
  }

  static getBankAccount() {
    return FetchHelper.get(window.Api.BankAccounts);
  }

  static refund(sale) {
    return FetchHelper.delete(`${window.Api.Sales}/${sale.id}`);
  }

  static dispatch(data) {
    return FetchHelper.post(`${window.Api.Deliveries}`, data);
  }

  static delivered(delivery) {
    return FetchHelper.patch(
      `${window.Api.Deliveries}/${delivery.sale}`,
      delivery
    );
  }

  static collectable(data) {
    return FetchHelper.post(`${window.Api.Collections}`, data);
  }

  static collected(collection) {
    return FetchHelper.patch(
      `${window.Api.Collections}/${collection.sale}`,
      collection
    );
  }

  static getDomain(website) {
    let url = window.Api.Domains + "?website_id=" + website.id;
    return FetchHelper.get(url);
  }

  static deleteDomain(domain) {
    return FetchHelper.delete(window.Api.Domains + "/" + domain.id);
  }

  static searchDomains(data) {
    return FetchHelper.post(window.Api.Domains + "/check", data);
  }

  static purchaseDomain(data) {
    return FetchHelper.post(window.Api.Domains, data);
  }

  static renewDomain(domain) {
    return FetchHelper.patch(`${window.Api.Domains}/${domain.id}`, domain);
  }

  static slugify(name) {
    return FetchHelper.post(
      `${window.Api.Websites}/slugify`,
      { name },
      false,
      false
    );
  }

  static createWebsite(data) {
    return FetchHelper.post(`${window.Api.Websites}`, data);
  }

  static purchaseSubscription(data) {
    return FetchHelper.post(`${window.Api.Subscriptions}`, data);
  }

  static updateSubscription(subscription, data) {
    data.active = true;
    return FetchHelper.patch(
      `${window.Api.Subscriptions}/${subscription.company}`,
      data
    );
  }

  static getPackages() {
    let endpoint = `${window.Api.Packages}?paginated=false&active=true&switchable=true`;
    let company = AuthManager.currentUser.company;
    return FetchHelper.get(endpoint);
  }

  static getPackage(id) {
    return FetchHelper.get(`${window.Api.Packages}/${id}`);
  }

  static getPackageBySlug(slug) {
    let url = `${window.Api.Packages}/find/?website_url=${window.location.hostname}`
    if(slug){
      url += `&package_slug=${slug}`
    }
    return FetchHelper.get(url, false);
  }


  static getCoupon(slug) {
    return FetchHelper.get(`${window.Api.Coupons}/${slug}`);
  }

  static createLoginHash() {
    return FetchHelper.post(`${window.Api.Hashes}`, { type: "login" });
  }

  static saveNote(note) {
    return FetchHelper.post(window.Api.Notes, note);
  }

  static getNotesV2(submissions) {
    return FetchHelper.get(
      `${window.Api.Notes}?submissions_id=${submissions.id}`
    );
  }

  static getNotes(contact) {
    return FetchHelper.get(`${window.Api.Notes}?contact_id=${contact.id}`);
  }

  static deleteNote(note) {
    return FetchHelper.delete(`${window.Api.Notes}/${note.id}`);
  }

  static updateContact(contact) {
    return FetchHelper.patch(`${window.Api.Contacts}/${contact.id}`, contact);
  }

  static getArticleBySlug(slug, websiteId) {
    return FetchHelper.get(
      `${window.Api.Articles}/${slug}?website_id=${websiteId}`
    );
  }

  static saveArticle(data) {
    let articleData = Backend._getArticleData(data);
    return FetchHelper.post(window.Api.Articles, articleData);
  }

  static updateArticle(data) {
    let articleData = Backend._getArticleData(data);
    return FetchHelper.patch(`${window.Api.Articles}/${data.id}`, articleData);
  }

  static _getArticleData(article) {
    let data = { ...article };
    data.categories = article.categories.map((category) => category.id);
    data.banner = data.banner.id;
    data.thumbnail = data.thumbnail.id;

    if (article.gallery) {
      data.gallery.image_ids = article.gallery.images.map((image) => image.id);
    }

    return data;
  }

  static getArticleCategories(website) {
    let websiteId = website
      ? website.website_id
      : AuthManager.currentWebsite.id;
    return FetchHelper.get(
      `${window.Api.ArticleCategories}?website_id=${websiteId}&paginated=false`
    );
  }

  static addArticleCategory(data) {
    return FetchHelper.post(window.Api.ArticleCategories, data);
  }

  static transferDomain(url) {
    let data = {
      name: url,
      type: "transfer",
      website: AuthManager.currentWebsite.id,
    };
    return FetchHelper.post(window.Api.Domains, data);
  }

  static activationCheck(domain) {
    return FetchHelper.post(
      `${window.Api.Domains}/${domain.id}/activation-check`,
      {}
    );
  }

  static updateDNS(domain, data) {
    data = JSON.parse(JSON.stringify(data));
    if (data.priority) {
      data.priority = parseInt(data.priority);
    }
    return FetchHelper.post(`${window.Api.Domains}/${domain.id}/records`, data);
  }

  static deleteRecord(domain, data) {
    return FetchHelper.delete(
      `${window.Api.Domains}/${domain.id}/records`,
      data
    );
  }

  static getBranding() {
    return FetchHelper.get(
      `${window.Api.Branding}?website_url=${window.location.hostname}`,
      false
    );
  }

  static addGmailRecords(domain) {
    return FetchHelper.post(`${window.Api.Domains}/${domain.id}/gmail`);
  }

  static createForm(formData) {
    let data = { ...formData };
    data.website = data.website_id ? data.website_id : data.website;
    let url = `${window.Api.Forms}`;
    return FetchHelper.post(url, data);
  }

  static updateForm(formData) {
    let data = { ...formData };
    data.website = data.website_id ? data.website_id : data.website;
    data.company_members = data.company_members.map((companyMember) => {
      return companyMember.user.id;
    });
    let url = `${window.Api.Forms}/${data.id}`;
    return FetchHelper.patch(url, data);
  }

  static getForm(id) {
    let url = `${window.Api.Forms}/${id}`;
    return FetchHelper.get(url)
  }

  static getATSForm() {
    let url = `${window.Api.Forms}?website_id=${AuthManager.currentWebsite.id}&type=crm`;
    return FetchHelper.get(url).then((response) => {
      return response.results[0];
    });
  }

  static getCompanyMembers() {
    return FetchHelper.get(window.Api.Staff);
  }

  static _parseSubmission(submission) {
    submission.answers.map((answer) => {
      if (answer.field.type != "multi_select") {
        delete answer["options_selected"];
      }
      if (answer.option_selected) {
        answer.option_selected = answer.option_selected.id;
      }
      if (
        answer.field.type == "multi_select" &&
        answer.options_selected.length > 0
      ) {
        let options = [];
        answer.options_selected.map((item) => {
          options.push(item.id);
        });
        answer.options_selected = options;
      }
      answer.field = answer.field.id;
      answer.file = answer.file?.id;
      answer.image = answer.image?.id;
    });
    submission.status = submission.status?.id;
    return submission;
  }

  static _removeEmptyValues(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }

  static updateSubmission(submission) {
    submission = JSON.parse(JSON.stringify(submission));
    submission = this._parseSubmission(submission);
    submission = this._removeEmptyValues(submission);
    submission.answers.map((answer) => {
      answer = this._removeEmptyValues(answer);
    });
    return FetchHelper.patch(
      window.Api.Submissions + "/" + submission.id,
      submission
    );
  }

  static updateSubmissionPosition(submission) {
    let data = { status: submission.status, position: submission.position };
    return FetchHelper.patch(
      window.Api.Submissions + "/" + submission.id,
      data
    );
  }

  static updateSubmissionStatus(status) {
    return FetchHelper.patch(
      window.Api.SubmissionStatus + "/" + status.id,
      status
    );
  }

  static createSubmission(submission) {
    let data = JSON.parse(JSON.stringify(submission));
    data = this._parseSubmission(data);
    return FetchHelper.post(window.Api.Submissions, data);
  }

  static getSubmission(id) {
    return FetchHelper.get(window.Api.Submissions + "/" + id);
  }

  static getATS() {
    return FetchHelper.get(
      window.Api.ATS + "?website_id=" + AuthManager.currentWebsite.id
    );
  }

  static getLeads() {
    let url = window.Api.Submissions;
    url += "?website_id=" + AuthManager.currentWebsite.id + "&form_type=crm";
    return FetchHelper.get(url);
  }

  static getLatestSubmission() {
    return FetchHelper.post(window.Api.Submissions, true);
  }

  static getForms() {
    let url = window.Api.Forms + "?website_id=" + AuthManager.currentWebsite.id;
    return FetchHelper.get(url).then((response) => {
      return response.results;
    });
  }

  static addNewSubmissionStatus(status) {
    return FetchHelper.post(window.Api.SubmissionStatus, status);
  }

  static deleteSubmissionStatus(status) {
    let data = { active: false };
    return FetchHelper.patch(
      window.Api.SubmissionStatus + "/" + status.id,
      data
    );
  }

  static addCoupon(coupon) {
    let data = JSON.parse(JSON.stringify(coupon));
    return FetchHelper.post(window.Api.Vouchers, data);
  }

  static updateCoupon(coupon) {
    let data = JSON.parse(JSON.stringify(coupon));
    return FetchHelper.patch(`${window.Api.Vouchers}/${data.id}`, data);
  }


  static getAdminConversations() {
    return FetchHelper.get(
      `${window.Api.Conversations}?with_admin=true&website_id=${AuthManager.currentWebsite.id}`
    ).then((response) => response.results);
  }

  static getConversation(id){
    return FetchHelper.get(`${window.Api.Conversations}/${id}`)
  }

  static updateVisitorProfile(visitor) {
    let data = { ...visitor };

    return FetchHelper.patch(`${window.Api.Visitors}/${visitor.id}`, data);
  }

  static sendMessage(data) {
    return FetchHelper.post(`${window.Api.Messages}`, data)
  }

  static createSite(data) {
    return FetchHelper.post(`${window.Api.Sites}`, data, false , false);
  }
}
