import React from 'react'

import Div from "../components/layouts/Div"

import BankAccountModal from "../components/modal/BankAccountModal"

import Backend from "../../utils/Backend"
import Notify from "../../utils/Notify"

export default class BankDetails extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      createMode: false,
      bankAccount: null,
      stripeAccountId: props.stripeAccountId,
      showBankAccountModal: false
    }
  }

  componentDidMount(){
    this._load()

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const status = params.get('s')

    if(status === "t"){
      Notify.success("Stripe account successfully updated")
    }
    else if(status === "f"){
      Notify.error("Stripe account not updated")
    }
  }

  _load(){
    this.setState({ loadingAccount: true })
    Backend.getConnectData()
    .then(data => {
      this.setState({
        data,
        loading: false,
      })
    })
    .catch(error => {
      this.setState({ loading: false })
    })
  }

  _goToStripe(){
    this.setState({ loading: true })
    Backend.getConnectData()
    .then(data => {
      if(data.activation_link){
        window.location = data.activation_link
      }
      this.setState({ data, loading: false })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  render(){
    let {
      loading,
      data,
    } = this.state

    if(!data){
      return null
    }

    return (
      <>
        <Div
          className="kt-wizard-v2__content"
          data-ktwizard-type="step-content"
          data-ktwizard-state="current"
          disabled={loading}
          spinner={true}
        >
          <div className="kt-heading kt-heading--md">
            Bank Details
          </div>
          <div className="kt-form__section kt-form__section--first">
            <div className="kt-wizard-v2__form">
            { data.requirements.eventually_due.length > 0 &&
              <p>
                Our Payment processor, Stripe, requires more information about your business, please click below to provide it.
              </p>
            }
            { data.requirements.eventually_due.length === 0 &&
              <p>
                Your account is fully connected to Stripe, click below to visit your Stripe dashboard
              </p>
            }
            </div>
          </div>
        </Div>
        <div className="kt-form__actions">
          { data.requirements.eventually_due.length > 0 &&
            <button
              className="btn btn-brand btn-mdbtn-tall btn-wide kt-font-bold kt-font-transform-u"
              onClick={e => {
                e.preventDefault()
                this._goToStripe()
              }}
            >
              Go to Stripe
            </button>
          }
          { data.requirements.eventually_due.length === 0 &&
            <a
              href="https://dashboard.stripe.com"
              target="_blank"
              className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
            >
              Visit Stripe Dashboard
            </a>
          }
        </div>
      </>
    )
  }
}
