import React from 'react';

import TextField from '@material-ui/core/TextField';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FormFieldModal from "../modal/FormFieldModal";

import Switch from "react-switch";
import renderHTML from 'react-render-html';

export default class FormField extends React.Component {

  constructor(props){
    super(props)
    this.handleMouseHover = this._handleMouseHover.bind(this)
    this.state = {
      form: props.form,
      hovering: false,
      index: props.index,
      rowIndex: props.rowIndex,
      formField: props.formField
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _handleMouseHover() {
    let {
      hovering
    } = this.state

    this.setState({hovering: !hovering})
  }

  _renderOptions(field){
    let {
      index,
      rowIndex
    } = this.state

    let hideDelete = field.type === "contact_first_name" || field.type === "contact_last_name" || field.type === "contact_email"

    return (
      <>
        <div className="toolbar">

          <a
            href="javascript:void(0);"
            className="btn btn-icon btn-hover-light-primary"
            onClick={() => {
              this.setState({ showOptionsModal: true, selectedFormField: field})
            }}
          >
            <i className="fa fa-cog text-white"/>
          </a>

          {
            !hideDelete &&
            <a
              href="javascript:void(0);"
              className="btn btn-icon btn-hover-light-primary remove-element"
              onClick={() => this.props.onRemoveItem(rowIndex, index)}
            >
              <i className="fa fa-times text-white"/>
            </a>
          }

        </div>
        
      </>
    )
  }
  
  _renderDateTimeFields(type, formField){
    if(type === "date"){
      return (
        <DatePicker
          fullWidth
          disabled
          inputVariant="outlined"
          label={formField.title}
          emptyLabel={''}
          value={null}
          disableToolbar
          onChange={date => {

          }}
        />
      )
    }else if(type === "time"){
      return (
        <TimePicker
          fullWidth
          disabled
          inputVariant="outlined"
          label={formField.title}
          emptyLabel={''}
          value={null}
          disableToolbar
          onChange={date => {

          }}
        />
      )
    }else if(type === "datetime"){
      return (
        <DateTimePicker
          fullWidth
          disabled
          inputVariant="outlined"
          label={formField.title}
          emptyLabel={''}
          value={null}
          disableToolbar
          onChange={date => {

          }}
        />
      )
    }
  }

  _renderField(){
    let {
      formField,
    } = this.state

    let type = formField.type

    if(type === "single_select" && formField.alignment === "horizontal"){
      return (
        <FormControl
          component="fieldset"
          fullWidth
          disabled
        >
          <FormLabel component="legend">{formField.title}</FormLabel>
          <RadioGroup>
            {
              formField.options.map(option => (
                <FormControlLabel value={option.text} control={<Radio />} label={option.text} />
              ))
            }
          </RadioGroup>
        </FormControl>
      )
    }else if(type === "multi_select"){
      return (
        <FormControl
          component="fieldset"
          fullWidth
          disabled
        >
          <FormGroup
            row
            component="fieldset"
            fullWidth
          >
            <FormLabel component="legend">{formField.title}</FormLabel>
            {
              formField.options.map(option => (
                <FormControlLabel control={<Checkbox />} label={option.text} />
              ))
            }
          </FormGroup>
        </FormControl>
      )
    }else if(type === "single_select" && formField.alignment === "vertical"){
      return (
        <FormControl
          fullWidth
          variant={"outlined"}
        >
          <InputLabel id={formField.id}>{formField.title}</InputLabel>
          <Select
            labelId={formField.id}
            disabled
          >
            {
              formField.options.map(option => (
                <MenuItem>{option.text}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      )
    }else if(type === "date" || type === "datetime" || type === "time"){
      return (
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
        >
          {this._renderDateTimeFields(type, formField)}
        </MuiPickersUtilsProvider>
      )
    }else if(type === "boolean"){
      if(formField.is_required){
        let label = formField.title;
        if(formField.url){
          label = `<a href=${formField.url} target="_blank">${formField.title}</a>`
        }
        return (
          <>
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox disabled />}
                label={renderHTML(label)}
              />
            </FormGroup>
          </>
        )
      }else{
        return (
          <>
            <label className="col-lg-6 col-form-label">{formField.title}</label>
            <Switch
              disabled
              onChange={checked => {
                formField.is_unique = checked
                this.setState({ formField })
              }}
              checked={formField.is_unique}
              aria-labelledby="neat-label"
              onColor="#333333"
              offColor="#F3F6F9"
              checkedIcon={null}
              uncheckedIcon={null}
              className="c-switch"
            />
          </>
        )
      }
    }else{
      return (
        <TextField
          label={formField.title}
          disabled
          fullWidth
          variant={"outlined"}
          multiline={type === "textarea"}
          rows={type === "textarea" ? 4 : 1}
        />
      )
    }
  }

  render(){
    let {
      form,
      index,
      style,
      rowIndex,
      hovering,
      formField,
      showOptionsModal,
      selectedFormField
    } = this.state

    return (
      <>
        <div
          className={hovering?"form-item flaticon-more hover":"form-item"}
          style={style}
          onMouseEnter={this.handleMouseHover}
          onMouseLeave={this.handleMouseHover}
          draggable={true}
          onDragStart={e => {
            e.dataTransfer.setData("text/plain", JSON.stringify({row:rowIndex, index:index}))
            e.dataTransfer.dropEffect = "copy"
            this.props.onShowDropzones(true)
          }}
          onDragEnd={e => {
            this.props.onShowDropzones(false)
          }}
        >
          {this._renderField()}
          {hovering && this._renderOptions(formField)}
        </div>
        {
          showOptionsModal &&
          <FormFieldModal
            form={form}
            show={showOptionsModal}
            formField={selectedFormField}
            onHide={() => this.setState({showOptionsModal: false, selectedFormField: null})}
            updateFormField={selectedFormField => {
              this.setState({showOptionsModal: false, selectedFormField: null})
              this.props.onUpdate(selectedFormField)
            }}
          />
        }
      </>
    )

  }
}