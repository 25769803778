import React from 'react'

import Board from './Board'
import ATSListHeader from './ATSListHeader'
import ATSSubmissionCard from './ATSSubmissionCard'

import SubmissionModal from '../modal/SubmissionModal'

import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'
import AuthManager from "../../../utils/AuthManager";


export default class ATSBoard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
    }
  }

  componentDidMount(){
    this._load()
  }

  _load(){
    Backend.getATS()
    .then((response) => {
      let lists = this._parseData(response)
      this.setState({lists, loading: false})
    })
    .catch((error) => {
      this.setState({loading: false})
      Notify.error(error.message)
    })
  }

  _parseData(response){
    return response.statuses.map(status => {
      let list = { ...status }

      let cards = response.submissions.filter(submission => submission.status === status.id)
      return {
        ...status,
        cards
      }
    })
  }

  _onSubmissionUpdated(submission){
    let { lists, selectedSubmissionId } = this.state

    if(!selectedSubmissionId){
      this.setState({
        showSubmissionModal: false
      }, () => this._load())
    }else{
      lists = lists.map(list => {
        list.cards = list.cards.map(card => {
          if(card.id === submission.id){
            card = submission
          }
          return card
        })
        return list
      })

      this.setState({
        lists,
        selectedSubmissionId: null,
        showSubmissionModal: false
      })
    }
  }

  _updateSubmission(submission, status, position, index){
    submission.status = status.id
    submission.position = position
    Backend.updateSubmissionPosition(submission)

    let { lists }  = this.state
    lists = lists.map(list => {
      list.cards = list.cards.filter(card => card.id !== submission.id)
      if(list.id !== status.id){
        return list
      }
      list.cards.splice(index, 0, submission)
      return list
    })
    this.setState({ lists })
  }

  _updateSubmissionStatus(status, position, index){
    status.position = position
    Backend.updateSubmissionStatus(status)

    let { lists }  = this.state
    let list = lists.find(o => o.id === status.id)
    list.position = position
    lists = lists.filter(o => o.id !== list.id)
    lists.splice(index, 0, list)
    this.setState({ lists })
  }

  _showSubmissionModal(submission){
    this.setState({
      showSubmissionModal: true,
      selectedSubmissionId: submission.id
    })
  }

  _showSubmissionAddModal(list){
    this.setState({
      showSubmissionModal: true,
      selectedList: list.id
    })
  }

  _addNewList(newList){
    newList.website = AuthManager.currentWebsite.id
    Backend.addNewSubmissionStatus(newList).then(response => {
      this._load()
    }).catch(error => {
      Notify.error(error.message)
    })
  }

  _updateList(list){
    Backend.updateSubmissionStatus(list).then(response => {
      Notify.success("List updated successfully")
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  _deleteList(list){
    if(list.cards.length > 0) return

    Backend.deleteSubmissionStatus(list).then(response => {
      Notify.success("List deleted successfully")
      this._load()
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  render() {
    let {
      lists,
      showSubmissionModal,
      selectedSubmissionId,
      selectedList
    } = this.state

    if(!lists){
      return null
    }

    return (
      <div className="board-container metronic-7-0-5 kanban-v2 mt-25px">
        <Board
          lists={lists}
          onListReordered={(list, position, listOrderById, index) => this._updateSubmissionStatus(list, position, index)}
          onCardMoved={(card, list, position, index) => this._updateSubmission(card, list, position, index)}
          onCardClicked={(card, index) => this._showSubmissionModal(card)}
          onAddCardClicked={(list, index) => this._showSubmissionAddModal(list)}
          onAddListClicked={(newList) => this._addNewList(newList)}
          onSettingsUpdated={(list) => this._updateList(list)}
          onListDeleted={(list) => this._deleteList(list)}
          renderCard={(card, index, list) => <ATSSubmissionCard submission={card}/>}
          renderListHeader={(list, index) => (
            <ATSListHeader
              list={list}
              onAddCardClicked={() => {}}
            />
          )}
        />
        {
          showSubmissionModal &&
          <SubmissionModal
            show={showSubmissionModal}
            list={selectedList}
            submissionId={selectedSubmissionId}
            onUpdated={submission => this._onSubmissionUpdated(submission)}
            onHide={() => this.setState({selectedSubmissionId: null, showSubmissionModal: false})}
          />
        }
      </div>
    )
  }
}
