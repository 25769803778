import React from 'react'

import { Route, Switch, withRouter } from 'react-router-dom'

import { CopyToClipboard } from 'react-copy-to-clipboard';

import Div from "./components/layouts/Div"

import Alert from "./components/alert/Alert"
import ExplainerAlert from './components/alert/ExplainerAlert';

import ActivateShopForm from './components/product/ActivateShopForm'
import ConfirmModal from './components/modal/ConfirmModal'

import DNSRecordModal from './components/modal/DNSRecordModal'
import PurchaseDomainModal from './components/modal/PurchaseDomainModal'
import RenewDomainModal from './components/modal/RenewDomainModal'

import Aside from './components/Aside'

import AmplyDomainSettings from './components/settings/AmplyDomainSettings'
import ExternalDomainSettings from "./components/settings/ExternalDomainSettings"
import BuyDomainSettings from "./components/settings/BuyDomainSettings"

import DomainEngagementCard from "./components/cards/DomainEngagementCard"

import BankDetails from './shop/BankDetails'
import Settings from './shop/Settings'

import Backend from "../utils/Backend"
import AuthManager from "../utils/AuthManager"
import Website from "../utils/Website"
import Notify from "../utils/Notify"


class DomainSettings extends React.Component {
  constructor(props){
    super(props)

    let tabs = [
      {
        title:`01 - Use ${window.General.Branding.Name} Domain`,
        subtitle:`View your free ${window.General.Branding.Name} domain`,
        icon:"earth-globe",
        url: "/domain/amply"
      },
      {
        title:"02 - Use Existing Domain",
        subtitle:`Connect your existing domain to ${window.General.Branding.Name}`,
        icon:"attachment",
        url: "/domain/existing"
      },
      {
        title:"03 - Buy New Domain",
        subtitle:"Get a domain for your site",
        icon:"shopping-basket",
        url: "/domain/purchased"
      },
    ]

    this.state = {
      tabs,
      dns: {},
      loading: false,
      loadingDNS: true,
      website: AuthManager.currentWebsite,
      activeTabIndex: this._getActiveTabIndex(tabs),
      externalDomain: AuthManager.currentWebsite.url
    }

  }

  componentDidMount(){
    this._load()
  }

  _load(){
    this._loadDomain()
    this._loadDNS()
  }

  _loadDomain(){
    let {
      website
    } = this.state

    this.setState({ loading: true })
    Backend.getDomain(AuthManager.currentWebsite)
    .then(purchasedDomain => {
      this.setState({
        purchasedDomain,
        loading: false
      })
    })
    .catch(error => {
      this.setState({ loading: false })
    })
  }

  _loadDNS(){
    let {
      website
    } = this.state

    this.setState({ loadingDNS: true })
    Backend.getDNSRecords(website)
    .then(dns => {
      this.setState({
        dns,
        loadingDNS: false
      })
    })
    .catch(error => {
      this.setState({ loadingDNS: false })
      Notify.error(error.message)
    })
  }

  _refreshDNS(){
    let {
      website
    } = this.state

    return Backend.getDNSRecords(website)
    .then(dns => {
      this.setState({
        dns,
      })
    })
    .catch(error => {
      Notify.error(error.message)
    })
  }

  _deletePurchasedDomain(){
    let {
      purchasedDomain
    } = this.state

    this.setState({ loadingPurchasedDomain: true })
    Backend.deleteDomain(purchasedDomain)
    .then(() => {
      this.setState({ purchasedDomain: null , showDeletePurchasedDomainModal: false})
      Notify.success("Domain was successfully removed")
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loadingPurchasedDomain: false })
    })
  }

  _deleteExternalDomain(){
    let {
      website,
      externalDomain
    } = this.state

    let data = {
      url: null
    }
    this.setState({ loadingExternalDomain: true })
    Backend.updateWebsite(website, data)
    .then(website => {
      AuthManager.currentWebsite.url = website.url
      this.setState({
        externalDomain: null,
        loadingExternalDomain: false
      })
    })
    .catch(error => {
      this.setState({
        loadingExternalDomain: false
      })
      Notify.error(error.message)
    })
  }

  _deleteRecord(){
    let {
      website,
      selectedRecord,
      purchasedDomain
    } = this.state


    this.setState({ loadingDeleteRecord: true })
    Backend.deleteRecord(purchasedDomain, selectedRecord)
    .then(() => this._refreshDNS())
    .then(() => {
      this.setState({
        selectedRecord: null,
        loadingDeleteRecord: false,
        showConfrmDeleteRecordModal: false
      })
      Notify.success("Record Successfully Deleted")
    })
    .catch(error => {
      this.setState({
        loadingDeleteRecord: false,
      })
      Notify.error(error.message)
    })
  }

  _activationCheck(){
    let {
      purchasedDomain
    } = this.state

    this.setState({ loadingActivationCheck: true })
    Backend.activationCheck(purchasedDomain)
    .then(website => {
      this.setState({ loadingActivationCheck: false })
      Notify.success(`${window.General.Branding.Name} is now checking the nameservers for ${purchasedDomain.name}. Please wait a few hours for an update.`)
    })
    .catch(error => {
      this.setState({ loadingActivationCheck: false })
      Notify.error(error.message)
    })
  }

  _addGmailRecords(){
    let {
      purchasedDomain
    } = this.state

    this.setState({ loadingGmailRecords: true })
    Backend.addGmailRecords(purchasedDomain)
    .then(() => this._refreshDNS())
    .then(website => {
      this.setState({ loadingGmailRecords: false, showConfirmAddGmailRecordsModal: false })
      Notify.success(`Gmail Records Added`)
    })
    .catch(error => {
      this.setState({ loadingGmailRecords: false })
      Notify.error(error.message)
    })
  }

  _getActiveTabIndex(tabs){
    const { pathname } = this.props.location;
    for(var i=0; i < tabs.length; i++){
      let tab = tabs[i]
      if(pathname === tab.url){
        return i
      }
    }
    return 0
  }

  _renderCompleteTransfer(){
    let {
      dns,
      website,
      purchasedDomain,
      loadingPurchasedDomain,
      loadingActivationCheck,
      showDeletePurchasedDomainModal
    } = this.state

    let stepOneTitle = "Log Into Your Domain Provider (E.g. GoDaddy, LetsHost...)"
    if(dns.original_registrar_data.name){
      stepOneTitle = (
        <>
          Log Into Your <a href={`${dns.original_registrar_data.url}`}>{dns.original_registrar_data.name} Account</a>
        </>
      )
    }

    return (

        <Div
          className="col-lg-12 px-0"
          disabled={loadingPurchasedDomain}
        >
          <div className="kt-portlet">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">
                  Complete Transferring Your Domain
                </h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              <p><b>{ purchasedDomain.name }</b> is not yet active on {window.General.Branding.Name}</p>
              <p>Follow our handy guide below to get your doman active</p>
            </div>
            <div className="kt-portlet__foot">
              <div className="kt-form__actions ml-auto">
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  disabled={loadingPurchasedDomain}
                  onClick={() => this.setState({ showDeletePurchasedDomainModal: true })}
                >
                  Cancel Transfer
                </button>
              </div>
            </div>
          </div>

          <div className="kt-portlet">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">
                  1. { stepOneTitle }
                </h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              <div>Remove these nameservers:</div>
              <ul className="nameservers">
                { dns.original_name_servers?.map(name_server => {
                    return (
                      <li>
                        { name_server }
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>

          <div className="kt-portlet">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">
                  2. Replace Them With {window.General.Branding.Name}'s Nameservers
                </h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              <div className="nameservers">
                { dns.name_servers.map((nameserver, index) => {
                    return (
                      <>

                        <div className="mb-2">Nameserver {index + 1}</div>
                        <div className="nameserver d-inline-flex align-items-center">

                          <div className="col-auto name">
                            { nameserver }
                          </div>

                          <div className="col">
                            <CopyToClipboard
                              text={nameserver}
                              onCopy={() => {
                                Notify.success("Copied to clipboard")
                              }}
                            >
                              <button className="btn btn-outline-dark" id="copyButton" ata-clipboard-action="copy" data-clipboard-target="#shareLink">
                                Copy Link
                              </button>
                            </CopyToClipboard>
                          </div>

                        </div>

                      </>
                    )
                  })
                }
              </div>
              <p class="mt-1 mb-0">
                <b>Save your changes</b>
                <p>Changes can take 24 hours to process.</p>
              </p>
            </div>
          </div>


          <div className="nameserver row align-items-center bg-transparent b-color m-0 mb-4">
            <div className="col">
              {window.General.Branding.Name} periodically checks for nameserver updates. To initiate a nameserver check now, Check nameservers.
            </div>
            <div className="col-auto">
              <button
                className="btn btn-outline-dark"
                onClick={() => this._activationCheck()}
                disabled={loadingActivationCheck}
              >
                Check Nameservers
              </button>
            </div>
          </div>

          { this._renderDNSRecords(dns) }

          <ConfirmModal
            show={showDeletePurchasedDomainModal}
            title={`Are You Sure?`}
            message={`This will cancel the transfer of ${purchasedDomain.name}, however ${Website.getDomain(website)} will still work.`}
            loading={loadingPurchasedDomain}
            confirmButtonText="Cancel Transfer"
            cancelButtonText="No"
            buttonClassName="danger"
            onCancel={() => this.setState({ showDeletePurchasedDomainModal: false })}
            onConfirm={() => this._deletePurchasedDomain(purchasedDomain)}
          />

        </Div>

    )
  }

  _renderPurchasedDomain(){
    let {
      dns,
      website,
      purchasedDomain,
      loadingPurchasedDomain,
      showDeletePurchasedDomainModal,
    } = this.state

    return (
      <div className="row">
        <Div
          className="col-lg-12"
          disabled={loadingPurchasedDomain}
        >
          <div className="kt-portlet">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">
                  { purchasedDomain.name }
                </h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              Your domain and it's relevant settings are automatically managed by {window.General.Branding.Name}.
            </div>
            <div className="kt-portlet__foot">
              <div className="kt-form__actions ml-auto">
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  disabled={loadingPurchasedDomain}
                  onClick={() => this.setState({ showDeletePurchasedDomainModal: true })}
                >
                  Delete Domain
                </button>
                { purchasedDomain.renewal_status !== "auto" &&
                  <button
                    type="button"
                    className="btn btn-primary ml-3"
                    onClick={() => this.setState({ showPurchasedDomainModal: true })}
                  >
                    Renew
                  </button>
                }
              </div>
            </div>
          </div>

          { this._renderDNSRecords(dns) }

        </Div>

        <ConfirmModal
          show={showDeletePurchasedDomainModal}
          title={`Are You Sure?`}
          message={`People who visit ${purchasedDomain.name} will no longer see your website, however ${Website.getDomain(website)} will still work.`}
          loading={loadingPurchasedDomain}
          confirmButtonText="Delete Domain"
          buttonClassName="danger"
          onCancel={() => this.setState({ showDeletePurchasedDomainModal: false })}
          onConfirm={() => this._deletePurchasedDomain(purchasedDomain)}
        />
      </div>
    )
  }

  _renderExternalDomain(){
    let {
      dns,
      website,
      externalDomain,
      loadingExternalDomain,
      showDeleteExternalDomain
    } = this.state

    let domain = externalDomain.replace("https://", "")
    return (
      <div className="row">
        <Div
          className="col-lg-12"
          disabled={loadingExternalDomain}
        >
          <div className="kt-portlet">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">
                  { domain }
                </h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              { dns.custom &&
                <>
                  <Alert
                    type="info"
                    text={`${domain} needs to have the following CNAME added to it's DNS records to work. If you are unsure how to do this please get in touch.`}
                    closabled={false}
                  />
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label">CNAME:</label>
                    <div className="col-lg-9 my-auto">
                      <input
                        type="text"
                        className="form-control"
                        value={ dns.custom.data }
                        disabled
                      />
                      <p>{`Add the above as a CNAME to your DNS records to have '${domain}' go to your site`}</p>
                    </div>
                  </div>
                </>
              }
            </div>
            <div className="kt-portlet__foot">
              <div className="kt-form__actions  ml-auto">
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  disabled={loadingExternalDomain}
                  onClick={() => this.setState({ showDeleteExternalDomain: true })}
                >
                  Delete Domain
                </button>
              </div>
            </div>
          </div>
        </Div>
        <ConfirmModal
          show={showDeleteExternalDomain}
          title={`Are You Sure?`}
          message={`People who visit ${externalDomain} will no longer see your website, however ${Website.getDomain(website)} will still work.`}
          loading={loadingExternalDomain}
          confirmButtonText="Delete Domain"
          buttonClassName="danger"
          onCancel={() => this.setState({ showDeleteExternalDomain: false })}
          onConfirm={() => this._deleteExternalDomain(externalDomain)}
        />
      </div>
    )
  }

  _renderSettings(){
    let {
      tabs,
      domain,
      activeTabIndex
    } = this.state

    return (
      <>
        <div class="row">
          <DomainEngagementCard/>
        </div>
        <div className="kt-portlet">
          <div className="kt-portlet__body kt-portlet__body--fit">
            <div className="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2" data-ktwizard-state="step-first">

              <Aside
                tabs={tabs}
                activeTabIndex={activeTabIndex}
                onClick={(tab, index) => {
                  this.setState({
                    activeTabIndex: index
                  }, () =>{
                    this.props.history.push(tab.url)
                  })
                }}
              />

              <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
                <form className="kt-form" id="kt_form" style={{ width: '100%' }}>

                  <Switch>
                    <Route exact path="/domain/amply" component={AmplyDomainSettings} />
                    <Route exact path="/domain/existing"
                      render={(props) => (
                        <ExternalDomainSettings {...props}
                          onUpdatedExternalDomain={externalDomain => {
                            window.location = "/domain/amply"
                          }}
                          onTransferredDomain={domain => {
                            window.location = "/domain/amply"
                          }}
                        />
                      )}/>
                    <Route exact path="/domain/purchased"
                      render={(props) => (
                        <BuyDomainSettings {...props}
                          onPurchasedDomain={purchasedDomain => {
                            window.location = "/domain/amply"
                          }}
                        />
                      )}
                    />
                  </Switch>

                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  _renderDNSRecords(dns){
    let {
      selectedRecord,
      showRecordModal,
      purchasedDomain,
      loadingDeleteRecord,
      loadingGmailRecords,
      showConfrmDeleteRecordModal,
      showConfirmAddGmailRecordsModal
    } = this.state

    if(!dns){
      return null
    }

    return (
      <>
        <div className="kt-portlet">

          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label w-100 justify-content-between">
              <h3 className="kt-portlet__head-title">
                DNS Records
              </h3>
              { dns.dns_records?.find(record => record.type === "MX") == null &&

                <button
                  type="button"
                  className="btn btn-outline-dark ml-auto"
                  onClick={() => this.setState({ showConfirmAddGmailRecordsModal: true })}
                >
                  Add Gmail Records
                </button>
              }
              <button
                type="button"
                className="btn btn-outline-dark ml-3"
                onClick={() => this.setState({ selectedRecord: null, showRecordModal: true })}
              >
                Add Record
              </button>
            </div>
          </div>

          <div className="kt-portlet__body py-3">
            <div className="dataTables_wrapper">

              { dns.status === "pending" &&
                <ExplainerAlert
                  text={ `To avoid disruption to your email / other services ${window.General.Branding.Name} has automatically brought over DNS Records from your domain. Update these as necessary to ensure there is no disruption to your existing services. Please contact support for help with this if required.`}
                />
              }
              <table className="table table-striped- table-hover dataTable">

                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Content</th>
                    <th>TTL</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>

                <tbody>
                  { dns.dns_records?.map((record, index) => {
                    let ttl = "Auto"

                    if(record.ttl != null && record.ttl !== 1){
                      ttl = record.ttl
                    }

                    return (
                      <tr>
                        <td>
                          { record.type }
                        </td>
                        <td>
                          { record.name }
                        </td>
                        <td className="text-break">
                          { record.content }
                        </td>
                        <td>
                          { ttl }
                        </td>
                        <td width="80" align="center">
                          { record.editable &&
                            <>
                              <a
                                href="javascript:;"
                                class="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                                title="Edit"
                                onClick={() => this.setState({ selectedRecord: record, showRecordModal: true }) }
                              >
                                <i class="flaticon2-pen text-primary icon-sm"></i>
                              </a>
                              <a
                                href="javascript:;"
                                class="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
                                title="Delete"
                                onClick={() => this.setState({ selectedRecord: record, showConfrmDeleteRecordModal: true }) }
                              >
                                <i class="flaticon2-trash text-primary icon-sm"></i>
                              </a>
                            </>
                          }
                          { !record.editable &&
                            <>
                              <div className="py-2">Locked</div>
                            </>
                          }
                        </td>
                      </tr>
                    )
                  })
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <ConfirmModal
          show={showConfirmAddGmailRecordsModal}
          title={`Are You Sure?`}
          message={`Doing this will add the relevant Gmail MX Records to your DNS Records`}
          loading={loadingGmailRecords}
          confirmButtonText="Add Gmail Records"
          onCancel={() => this.setState({ showConfirmAddGmailRecordsModal: false })}
          onConfirm={() => this._addGmailRecords(purchasedDomain)}
        />

        <DNSRecordModal
          show={showRecordModal}
          record={selectedRecord}
          domain={purchasedDomain}
          onCancel={() => this.setState({ selectedRecord: null, showRecordModal: false })}
          onUpdated={() =>  {
            this._refreshDNS()
            .then(() => {
              this.setState({ selectedRecord: null, showRecordModal: false })
            })
          }}
        />

        <ConfirmModal
          show={showConfrmDeleteRecordModal}
          title={`Are You Sure?`}
          message={`You cannot under this action`}
          loading={loadingDeleteRecord}
          confirmButtonText="Delete Record"
          buttonClassName="danger"
          onCancel={() => this.setState({ showConfrmDeleteRecordModal: false })}
          onConfirm={() => this._deleteRecord(selectedRecord)}
        />
      </>
    )
  }

  render() {
    let {
      dns,
      loadingDNS,
      loadingDomain,
      externalDomain,
      purchasedDomain
    } = this.state

    if(loadingDomain || loadingDNS){
      return null
    }

    if(purchasedDomain){
      if(purchasedDomain.type === "transfer" && dns.status !== "active"){
        return this._renderCompleteTransfer()
      }
      return this._renderPurchasedDomain()
    }
    else if(externalDomain){
      return this._renderExternalDomain()
    }
    else{
      return this._renderSettings()
    }
  }
}

export default withRouter(DomainSettings);
