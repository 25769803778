import React from 'react'

import { Route, Switch, withRouter } from 'react-router-dom'

import Div from "../components/layouts/Div"

import ActivateShopForm from '../components/product/ActivateShopForm'

import Aside from '../components/Aside'

import ConnectForm from '../components/kyc/ConnectForm'

import Backend from "../../utils/Backend"
import AuthManager from "../../utils/AuthManager"
import Website from "../../utils/Website"
import Notify from "../../utils/Notify"


class Activate extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
    }

    this.activateShopForm = React.createRef()
  }

  componentDidMount(){
    if(AuthManager.currentUser.company.payments_connected){
      this.props.history.push("/shop/settings")
    }
  }

  _handleActivate(){
    this.setState({ loading: true })
    this.activateShopForm.current.save()
    .then(response => {
      this.setState({ loading: false })
      window.location = response.connect.activation_link
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  render() {
    let {
      loading,
    } = this.state

    return (
      <div className="row">
        <ActivateShopForm
          ref={this.activateShopForm}
          loadingText="Creating Your Shop..."
          confirmButtonText="Connect Bank Account"
          onConfirmPressed={() => this._handleActivate()}
        />
      </div>
    )
  }
}

export default withRouter(Activate);
