import React from "react"

import AsyncSelect from '../AsyncSelect'

import BaseTable from "./BaseTable"

import SaleActionsCell from "./cells/SaleActionsCell"

import ConfirmModal from '../modal/ConfirmModal'
import DispatchModal from '../modal/DispatchModal'
import CollectionModal from '../modal/CollectionModal'
import CollectedModal from '../modal/CollectedModal'
import DeliveredModal from '../modal/DeliveredModal'

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import AuthManager from '../../../utils/AuthManager'
import Currency from '../../../utils/Currency'
import Notify from '../../../utils/Notify'
import FetchHelper from "../../../utils/FetchHelper";

import moment from 'moment'

const STATUSES = [
  {
    label: "All",
    value: "all"
  },
  {
    label: 'Ordered',
    value: "ordered"
  },
  {
    label: "Refunded",
    value: "refunded"
  },
  {
    label: "Dispatched",
    value: "dispatched"
  },
  {
    label: "Collectable",
    value: "collectable"
  },
  {
    label: "Complete",
    value: "complete"
  },
]

export default class SalesTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {

    }

    this.table = React.createRef()
  }

  _refund(sale){
    this.setState({ refunding: true })
    Backend.refund(sale)
    .then(() => {
      this.setState({
        sale: null,
        showConfirmRefundModal: false,
        refunding: false
      })
      this.table.current.refresh()
      Notify.success("Refund successfully processed")
    })
    .catch(error => {
      this.setState({ refunding: false })
      Notify.error(error.message)
    })
  }

  _getColumns() {
    let {
      shop
    } = this.state

    return [
      {
        Header: 'Date',
        id: 'created_at',
        accessor: sale => moment(sale.created_at).format('DD/MM/YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: 'Amount',
        id: 'amount',
        accessor: sale => Currency.format(sale.amount, sale.currency)
      },
      {
        Header: 'Customer',
        id: 'first_name',
        accessor: sale => sale.first_name + " " + sale.last_name
      },
      {
        Header: 'Email',
        id: 'email',
        accessor: sale => sale.email
      },
      {
        Header: 'Status',
        id: 'status',
        Cell: rowInfo => {
          let sale = rowInfo.original

          let label = "Ordered"
          let type = "success"

          if(sale.delivery){
            label = General.toTitleCase(sale.delivery.status)
            type = "warning"

            if(sale.delivery.status === "complete"){
              type = "brand"
            }
          }

          if(sale.collection){
            label = General.toTitleCase(sale.collection.status)
            label = label.replace("Ready", "Collectable")
            type = "info"

            if(sale.collection.status === "complete"){
              type = "brand"
            }
          }

          if(sale.status === "refunded"){
            label = "Refunded"
            type = "danger"
          }

          return (
            <span
              className={`kt-badge kt-badge--${type} kt-badge--inline kt-badge--pill`}
            >
              { label }
            </span>
          )
        }
      },
      {
        Header: 'Actions',
        id: 'actions',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          return (
            <SaleActionsCell
              sale={rowInfo.original}
              onRefundPressed={sale => {
                this.setState({ sale, showConfirmRefundModal: true })
              }}
              onDispatchPressed={sale => {
                this.setState({ sale, showDispatchModal: true })
              }}
              onDeliveredPressed={sale => {
                this.setState({ sale, showDeliveredModal: true })
              }}
              onCollectablePressed={sale => {
                this.setState({ sale, showCollectionModal: true })
              }}
              onCollectedPressed={sale => {
                this.setState({ sale, showCollectedModal: true })
              }}
            />
          )
        }
      }
    ]
  }

  _renderSaleData(sale){
    return (
      <div style={{ background: '#f4f5fb' }}>
        <div className="row" style={{ margin: 20, padding: 20, background: 'white' }}>

          <div className="col-md">
            <h5>Products</h5>
            {
              sale.data.map(item => {
                let title = `${item.quantity}x ${item.product.name}`
                if(item.variant){
                  title += ` ( ${item.variant.name || item.variant.options.join(', ')} )`
                }
                return (
                  <div className="row align-items-center mb-2">
                    <div className="col-auto"><img class="img-v1" src={item.product.featured_image.thumbnail}/></div>
                    <div className="col"><a className="fc-1" href={'/products/'+item.product.slug}>{ title }</a></div>
                  </div>
                )
              })
            }
          </div>

          <div className="col-md">
            <h5>Customer</h5>
            <p class="mb-0">{sale.first_name + " " + sale.last_name}</p>
            <p class="mb-0">{sale.email}</p>
            <p class="mb-0">{sale.phone}</p>
            <br></br>
            <h5>Shipping Method</h5>
            <p class="mb-0">{ sale.shipping_method ? General.toTitleCase(sale.shipping_method) : "-" }</p>
          </div>

          <div className="col-md">
            <h5>Address</h5>
            { sale.location.raw.split(", ").map(addressPart => (
                <p class="mb-0">{addressPart}</p>
              ))
            }
          </div>

        </div>
      </div>
    )
  }

  render() {
    let {
      sale,
      refunding,
      showDispatchModal,
      showCollectionModal,
      showConfirmRefundModal,
      showDeliveredModal,
      showCollectedModal
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Sales}?shop_id=${AuthManager.currentWebsite.id}`}
          title="Sales"
          columns={columns}
          filters={STATUSES}
          searchFilterName="status"
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? 'visible' : 'hidden'
              }
            }
          }}
          SubComponent={row => this._renderSaleData(row.original)}
        />
        <ConfirmModal
          show={showConfirmRefundModal}
          title={`Are you sure?`}
          message="You cannot undo a refund, the customer will get back all fees includng any shipping costs."
          loading={refunding}
          confirmButtonText="Refund"
          buttonClassName="danger"
          onCancel={() => {
            this.setState({
              sale: null,
              showConfirmRefundModal: false
            })
          }}
          onConfirm={() => this._refund(sale)}
        />
        <DispatchModal
          show={showDispatchModal}
          sale={sale}
          onCancel={() => {
            this.setState({
              sale: null,
              showDispatchModal: false
            })
          }}
          onDispatched={() => {
            this.setState({
              sale: null,
              showDispatchModal: false
            })
            this.table.current.refresh()
          }}
        />
        <DeliveredModal
          show={showDeliveredModal}
          sale={sale}
          onCancel={() => {
            this.setState({
              sale: null,
              showDeliveredModal: false
            })
          }}
          onDelivered={() => {
            this.setState({
              sale: null,
              showDeliveredModal: false
            })
            this.table.current.refresh()
          }}
        />
        <CollectionModal
          show={showCollectionModal}
          sale={sale}
          onCancel={() => {
            this.setState({
              sale: null,
              showCollectionModal: false
            })
          }}
          onCollectable={() => {
            this.setState({
              sale: null,
              showCollectionModal: false
            })
            this.table.current.refresh()
          }}
        />
        <CollectedModal
          show={showCollectedModal}
          sale={sale}
          onCancel={() => {
            this.setState({
              sale: null,
              showDeliveredModal: false
            })
          }}
          onCollected={() => {
            this.setState({
              sale: null,
              showCollectedModal: false
            })
            this.table.current.refresh()
          }}
        />
      </>
    )
  }
}
