import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Div from "../layouts/Div"

import ExplainerAlert from "../alert/ExplainerAlert"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Website from "../../../utils/Website"
import Notify from "../../../utils/Notify"
import Currency from "../../../utils/Currency"
import General from "../../../utils/General"

import Select from "../common/Select"
import CardEntryForm from "../common/CardEntryForm"

export default class SubscriptionModal extends React.Component {
  constructor(props){
    super(props)
    const user = AuthManager.currentUser.user

    this.state = {
      loading: false,
      show: props.show,
      subscription: props.subscription,
      coupon: null,
    }
    this.cardEntryForm = React.createRef()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _initiatePurchase(){
    let {
      coupon
    } = this.state

    if(this._isFree()){
      this._handlePurchase()
    }
    else{
      this.setState({ loading: true })
      this.cardEntryForm.current.collectCardDetails()
    }
  }

  _getFormattedPrice(){
    let { subscription, coupon } = this.state

    let amount = subscription.amount
    if(subscription.interval == "one_time"){
      amount = coupon ? coupon.one_time_amount : subscription.amount
    }
    else {
      amount = coupon ? coupon.recurring_amount : subscription.amount
    }

    let formattedPrice = Currency.format(amount, subscription.currency)
    if(subscription.interval === "month"){
      formattedPrice += " per month"
    }
    else if(subscription.interval === "year"){
      formattedPrice += " per year"
    }
    else if(subscription.interval === "one_time" && subscription.one_time_upgrade_amount != null){
      if(subscription.one_time_upgrade_amount == 0){
        formattedPrice = `Free`
      }
      else{
        formattedPrice = `Pay ${Currency.format(subscription.one_time_upgrade_amount, subscription.currency)}`
      }
    }
    return formattedPrice
  }

  _isFree(){
    let {
      coupon,
      subscription
    } = this.state

    let amount = subscription.amount
    if(subscription.interval === "one_time"){
      amount = coupon ? coupon.one_time_amount : amount
      if(subscription.one_time_upgrade_amount != null){
        amount = subscription.one_time_upgrade_amount
      }
    }
    else{
      amount = coupon ? coupon.recurring_amount : amount
    }

    return amount == 0
  }

  _checkCoupons = General.debounce(() => {
    let {
      interval,
      couponCode,
    } = this.state

    if(!couponCode){
      this.setState({ coupon: null, errorCoupon: false })
      return
    }

    return Backend.getCoupon(couponCode)
      .then(coupon => {
        if(coupon.one_time_amount === 0 && coupon.recurring_amount === 0 && !interval){
          interval = "one_time"
        }
        this.setState({ coupon, interval, oadingCoupon: false, errorCoupon: false})
        Notify.success("Coupon Applied!")
      })
      .catch(error => {
        Notify.error("A coupon with this code does not exist")
        this.setState({ loadingCoupon: false, errorCoupon: true, coupon: null })
      })
    }, 1000)

  _handlePurchase(){
    let {
      cardData,
      coupon,
      subscription,
    } = this.state

    let { company } = AuthManager.currentUser

    this.setState({ loading: true })

    let data = {
      interval: subscription.interval,
      package: subscription.package,
      add_ons: {}
    }

    if(subscription.add_ons.map){
      subscription.add_ons.map(addOn => {
        data.add_ons[addOn.key] = {
          ...addOn
        }
      })
    }
    else{
      data.add_ons = { ...subscription.add_ons }
    }

    if(cardData){
      data = {
        ...data,
        ...cardData
      }
    }

    if(coupon){
      data.coupon = coupon.id
    }

    Backend.updateSubscription(company.subscription, data)
    .then(response => {
      if(response.requires_action){
        this.cardEntryForm.current._handleAction(response)
        return
      }
      this.setState({ loading: true })
      this.props.onSuccess(response)
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  _renderCouponInput(){
    let {
      coupon,
      couponCode,
      errorCoupon,
      subscription,
    } = this.state

    let couponBackground = errorCoupon ? "form-control-error" : ""

    if(subscription.amount == 0 || subscription.one_time_upgrade_amount == 0){
      return null
    }

    return (
      <div className="form-group row">
        <label className="col-lg-4 col-form-label">Coupon (optional):</label>
        <div className="col-lg-8 my-auto">
         <input
            className={`form-control form-control-solid ${couponBackground}`}
            value={couponCode}
            onChange={e => {
              let value = e.target.value ? e.target.value.toUpperCase() : e.target.value
              this.setState({
                 couponCode: value
              }, () => this._checkCoupons())
            }}
          />
        </div>
      </div>
    )
  }

  _renderCardEntryForm(){
    let {
      coupon
    } = this.state

    if(this._isFree()){
      return null
    }

    return (
      <CardEntryForm
        ref={this.cardEntryForm}
        showEmail={false}
        onCollected={cardData => {
          this.setState({ cardData }, () => this._handlePurchase())
        }}
        onCollectionFailed={error => {
          Notify.error(error.message)
          this.setState({ loading: false })
        }}
      />
    )
  }

  render() {
    let {
      show,
      loading,
      subscription
    } = this.state


    let title = subscription.active ? "Upgrade Subscription" : "Activate Subscription"

    let buttonText = subscription.active ? "Upgrade" : "Activate"
    buttonText += ` (${this._getFormattedPrice()})`
    let loadingText = subscription.active ? "Upgrading Subscription..." : "Activating Subscription..."

    return (
      <Modal
        show={show}
        onHide={() => {
          if(this.props.onCancel){
            this.props.onCancel()
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Div
            disabled={loading}
            spinner={loading}
            loadingText={loadingText}
          >
            { this._renderCouponInput() }
            { this._renderCardEntryForm() }
            {/* }<p>Please note, upgrading subscriptions will not refund any charges previously incurred</p> */}
          </Div>
        </Modal.Body>

        <Modal.Footer>

          { this.props.onCancel &&
            <button
              type="button"
              className="btn btn-secondary"
              disabled={loading}
              onClick={() => this.props.onCancel()}
            >
              Cancel
            </button>
          }

          <button
            type="button"
            className={`btn btn-primary`}
            disabled={loading}
            onClick={() => this._initiatePurchase()}
          >
            { buttonText }
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}

SubscriptionModal.defaultProps = {
}
