import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";

import Notify from "../../../utils/Notify"
import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"

export default class AddNewFormModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      show: props.show,
      isLoading: false,
      form: null,
    };

    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps));
  }

  _isFormValid(){
    let { name } = this.state

    let error = null
    if(!name){
      error = "Please enter a valid name"
    }

    if(error){
      Notify.error("Please enter a valid name")
      return false
    }

    return true
  }

  _getState(props) {
    return {
      ...props,
    };
  }

  _onSave(){
    let { name } = this.state

    if(!this._isFormValid()){
      return false
    }

    let data = {
      name,
      website: AuthManager.currentWebsite.id,
      submit_text: "REGISTER",
      form_fields: [
        [
          {
            title: "First Name",
            type: "text",
            is_required: true
          },
          {
            title: "Second Name",
            type: "text",
            is_required: true
          }
        ],
        [
          {
            title: "Email",
            type: "email",
            send_emails: true,
            is_required: true
          },
        ]
      ],
      terms_text: null,
      force_invite: false,
      count_no: 0,
      send_confirmation_email: true,
      allow_updates: true,
      send_updated_email: true,
    }

    this.setState({ isLoading: true })
    Backend.createForm(data)
    .then(form => {
      this.setState({
        error: null,
        isLoading: false
      })
      this.props.onAdded(form)
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ isLoading: false })
    })
  }

  render() {
    let { show, isLoading, name, error } = this.state;

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide();
          }
        }}
      >
        <div className="modal-content">
          <Modal.Header closeButton>
            <Modal.Title>Add New Form</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <div className="form-group row">
              <label className="col-lg-4 col-form-label">Name</label>
              <div className="col-lg-8 my-auto">
                <input
                  type="text"
                  name="name"
                  className="form-control form-control-solid"
                  value={ name }
                  onChange={e => this.setState({ name: e.target.value, error: null })}
                />
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => this.props.onHide()}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this._onSave()}
              disabled={isLoading}
            >
              Create Form
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}
