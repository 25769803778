import React from 'react'

import Div from "../components/layouts/Div"

import Backend from "../../utils/Backend"
import Notify from "../../utils/Notify"
import AuthManager from "../../utils/AuthManager"

export default class SuccessModalSettings extends React.Component {
  constructor(props){
    super(props)


    this.state = {
      loading: false,
      data: {
        lead_success_title: AuthManager.currentWebsite.lead_success_title,
        lead_success_message: AuthManager.currentWebsite.lead_success_message,
      }
    }

  }

  _handleChange(e) {
    let data = this.state.data
    data[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  _handleUpdate(){
    let {
      data
    } = this.state
    this.setState({ loading: true })

    Backend.updateWebsite(AuthManager.currentWebsite, data)
    .then(() => {
      this.setState({ loading: false })
      Notify.success("Success Modal Settings Updated")
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  render(){
    let {
      data,
      loading,
    } = this.state

    return (
      <>
        <Div
          className="kt-wizard-v2__content"
          data-ktwizard-type="step-content"
          data-ktwizard-state="current"
          disabled={loading}
        >
          <div className="kt-heading kt-heading--md">
            Success Modal
          </div>
          <div className="kt-form__section kt-form__section--first">
            <div className="kt-wizard-v2__form">

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Title:</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    name="lead_success_title"
                    value={data.lead_success_title}
                    onChange={e => this._handleChange(e)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Message:</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    name="lead_success_message"
                    value={data.lead_success_message}
                    onChange={e => this._handleChange(e)}
                  />
                </div>
              </div>

            </div>
          </div>
        </Div>
        <div className="kt-form__actions">
          <a
            href="javascript:void()"
            className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
            onClick={e => this._handleUpdate()}>
              Update
            </a>
        </div>
      </>
    )
  }
}
