import React from 'react';

const GridView = ({ className }) => {
  return (
    <svg className={className} width="648" height="379" viewBox="0 0 648 379" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="648" height="379" rx="20" fill="#F4F0EC"/>
      <rect x="21" y="21" width="606" height="338" rx="16" fill="white"/>
      <rect x="53" y="49" width="172" height="138" rx="10" fill="#F7F7F7"/>
      <path d="M82 75H138" stroke="#404040" stroke-width="10" stroke-linecap="round"/>
      <path d="M82 97H177" stroke="#B7B7B7" stroke-width="10" stroke-linecap="round"/>
      <rect x="83" y="128" width="111" height="39" rx="10" fill="#37DB70"/>
      <path d="M117 148H163" stroke="white" stroke-width="8" stroke-linecap="round"/>
      <rect x="53" y="198" width="172" height="138" rx="10" fill="#F7F7F7"/>
      <path d="M82 224H138" stroke="#404040" stroke-width="10" stroke-linecap="round"/>
      <path d="M82 246H177" stroke="#B7B7B7" stroke-width="10" stroke-linecap="round"/>
      <rect x="83" y="277" width="111" height="39" rx="10" fill="#37DB70"/>
      <path d="M117 297H163" stroke="white" stroke-width="8" stroke-linecap="round"/>
      <rect x="236" y="49" width="172" height="138" rx="10" fill="#F7F7F7"/>
      <path d="M265 75H321" stroke="#404040" stroke-width="10" stroke-linecap="round"/>
      <path d="M265 97H360" stroke="#B7B7B7" stroke-width="10" stroke-linecap="round"/>
      <rect x="266" y="128" width="111" height="39" rx="10" fill="#37DB70"/>
      <path d="M300 148H346" stroke="white" stroke-width="8" stroke-linecap="round"/>
      <rect x="236" y="198" width="172" height="138" rx="10" fill="#F7F7F7"/>
      <path d="M265 224H321" stroke="#404040" stroke-width="10" stroke-linecap="round"/>
      <path d="M265 246H360" stroke="#B7B7B7" stroke-width="10" stroke-linecap="round"/>
      <rect x="266" y="277" width="111" height="39" rx="10" fill="#37DB70"/>
      <path d="M300 297H346" stroke="white" stroke-width="8" stroke-linecap="round"/>
      <rect x="419" y="49" width="172" height="138" rx="10" fill="#F7F7F7"/>
      <path d="M448 75H504" stroke="#404040" stroke-width="10" stroke-linecap="round"/>
      <path d="M448 97H543" stroke="#B7B7B7" stroke-width="10" stroke-linecap="round"/>
      <rect x="449" y="128" width="111" height="39" rx="10" fill="#37DB70"/>
      <path d="M483 148H529" stroke="white" stroke-width="8" stroke-linecap="round"/>
      <rect x="419" y="198" width="172" height="138" rx="10" fill="#F7F7F7"/>
      <path d="M448 224H504" stroke="#404040" stroke-width="10" stroke-linecap="round"/>
      <path d="M448 246H543" stroke="#B7B7B7" stroke-width="10" stroke-linecap="round"/>
      <rect x="449" y="277" width="111" height="39" rx="10" fill="#37DB70"/>
      <path d="M483 297H529" stroke="white" stroke-width="8" stroke-linecap="round"/>
    </svg>
  )
}

export default GridView;
