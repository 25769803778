
import AuthManager from "./AuthManager";

export default class AddOns {

  static fillDetails(addOns){
    let addOnKeys = Object.keys(DEFAULT_ADD_ONS)
    addOns = addOnKeys.map(key => {
      let addOn = addOns[key]
      if(!ADD_ON_DETAILS[key]){
        return addOns
      }

      return {
        key,
        ...addOn,
        ...ADD_ON_DETAILS[key]
      }
    })

    addOns.sort(function (a, b) {
      if(a.enabled && b.enabled){
        if(a.visible && b.visible){
          if(a.price != b.price){
            return a.price < b.price ? 1 : -1
          }
          return a.title.localeCompare(b.title)
        }
        if(a.visible){
          return -1
        }
        else if(b.visible){
          return 1
        }
        return a.title.localeCompare(b.title)
        if(a.price == 0 & b.price == 0){
          return -1
        }
        else if(a.price != b.price){
          return a.price < b.price ? -1 : 1
        }
        return a.title.localeCompare(b.title)
      }
      else if(a.enabled != b.enabled){
        return a.enabled ? -1 : 1
      }
      else if(a.toggleable != b.toggleable){
        return a.toggleable ? -1 : 1
      }
      return a.enabled ? -1 : 1
    });
    return addOns
  }
}

const DEFAULT_ADD_ON = {
  price: 0,
  enabled: true,
  toggleable: false,
}

const DEFAULT_ADD_ONS = {
  domains: DEFAULT_ADD_ON,
  crm: DEFAULT_ADD_ON,
  blog: DEFAULT_ADD_ON,
  mobile_app: DEFAULT_ADD_ON,
  chat: DEFAULT_ADD_ON,
  visitors: DEFAULT_ADD_ON,
  files: DEFAULT_ADD_ON,
  team: DEFAULT_ADD_ON,
}

const ADD_ON_DETAILS = {
  shop: {
    icon: "la la-shopping-cart",
    title: "eCommerce Shop",
    description: "Spin up an eCommerce Shop and start selling your products and services to your customers. Control shipping and variables products with ease."
  },
  domains: {
    icon: "la la-globe",
    title: "Custom Domain",
    description: "Personalise your site with a custom domain. A custom domain helps strengthens your brand, gain customer trust and improve ranking on Google."
  },
  crm: {
    icon: "la la-group",
    title: "ATS & Contact Form",
    description: "A simple, yet powerful built in Application Tracking System. Customise your contact form fields, and manage your submissions and talent with ease."
  },
  blog: {
    icon: "la la-newspaper-o",
    title: "News & Blog",
    description: "Engage with your customers, and keep your website up to date with a blog. Making a blog helps attract new visitors by improving your ranking on Google"
  },
  mobile_app: {
    icon: "la la-mobile-phone",
    title: "Mobile App",
    description: "Manage your website on the go. From viewing analytics, managing sales, responding to visitors and leads use the Mobile App to manage your business easily"
  },
  chat: {
    icon: "la la-comment",
    title: "Live Customer Chat",
    description: "Chat with your visitors in real time. Live Chat lets you engage with people when their most interested in your site. Convert visitors into leads and leads into sales."
  },
  visitors: {
    icon: "la la-bar-chart",
    title: "Visitors & Traffic",
    description: "Get detailed insights on your site visitors. Learn what works and what doesn't by viewing the pages and actions your visitors take on your site to help optimise your KPIs"
  },
  files: {
    icon: "la la-inbox",
    title: "Media Bank",
    description: "Distribute files and documents to your visitors easily & effortlessly. The Media Bank lets your visitors download even large files in seconds from around the globe"
  },
  team: {
    icon: "la la-group",
    title: "Team Members",
    description: "Invite your co workers to help manage your site and grow your online presence. Team Members lets you get your team onboard to help with day to day admin"
  },
  web_designer: {
    icon: "la la-paint-brush",
    title: "Web Designer",
    description: "Get access to a Personal Web Designer to help maximise your sites potential. Your Personal Web Designer is reachable by Live Chat from within your dashboard at the click of a button"
  }
}
