export default class Product {

  /*
    The below function takes an array of arrays and
    returns the cartesian product of the entries of
    the arrays.

    This generates the different variants
    from our product options.
  */
  static getOptionsCartesian(args, product) {
    let variants = Array.from(product.variants)
    if(args.length === 0) return
    var r = [], max = args.length-1;
    function helper(arr, i) {
      for (var j=0, l=args[i].length; j<l; j++) {
        var a = arr.slice(0); // clone arr
        a.push(args[i][j]);
        if (i === max){
          let variant = variants.filter(variant => variant.options.sort().join(',') === Array.from(a).sort().join(','))
          if(variant.length > 0){
            r.push({options: a, price: variant[0].price, stock: variant[0].stock, images: variant[0].images})
          }else{
            r.push({options: a, price: product.price, stock: product.stock, images: []});
          }
        }
        else{
          helper(a, i+1);
        }
      }
    }
    helper([], 0);
    return r;
  }
}
