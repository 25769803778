import React from "react"

import ImageEdit from "../common/ImageEdit"

import Select from '../common/Select';

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Notify from "../../../utils/Notify";

const STATUSES = [
  {
    label: "Live",
    value: "live"
  },
  {
    label: "Draft",
    value: "draft"
  }
]


export default class GeneralSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      website: { ...AuthManager.currentWebsite }
    }
  }

  _update(){
    let {
      website
    } = this.state

    this.setState({ loading: true })
    Backend.updateWebsite(website)
    .then(website => {
      this.setState({
        website,
        loading: false
      })
    })
    .catch(error => {
      this.setState({
        loading: false
      })
      Notify.error(error.message);
    })
  }

  _handleChange(e) {
    let website = this.state.website
    website[e.target.name] = e.target.value
    this.setState({ website, error: null })
  }

  render() {
    let {
      website,
      loading
    } = this.state

    return (
      <>
        <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
          <div className="kt-heading kt-heading--md">Website Details</div>
          <div className="kt-form__section kt-form__section--first">
            <div className="kt-wizard-v2__form">

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Name</label>
                <div className="col-lg-9 my-auto">
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    name="name"
                    defaultValue={ website.name }
                    onChange={e => this._handleChange(e)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Status</label>
                <div className="col-lg-9 my-auto">
                  <Select
                    value={STATUSES.find(status => status.value === website.status )}
                    className="async-select-paginate"
                    classNamePrefix="async-select-paginate"
                    name="status"
                    onChange={option => {
                      website.status = option.value
                      this.setState({ website })
                    }}
                    options={STATUSES}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Favicon</label>
                <div className="col-lg-9 my-auto">
                  <ImageEdit
                    image={website.favicon}
                    onUpdated={favicon => {
                      website.favicon = favicon
                      this.setState({ website })
                    }}
                  />
                </div>
              </div>


            </div>
          </div>
        </div>
        <div className="kt-form__actions">
          <button
            className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
            disabled={loading}
            onClick={() => this._update()}
          >
            Save Changes
          </button>
        </div>
      </>
    )
  }
}
