import React from "react"

import Card from '../Card'

import { CSVLink } from "react-csv";
import ReactTable from "react-table"
import "react-table/react-table.css"

import Select from '../common/Select';

import LinearProgress from '@material-ui/core/LinearProgress'

import moment from "moment"

import Backend from '../../../utils/Backend'
import FetchHelper from '../../../utils/FetchHelper'
import General from '../../../utils/General'
import Notify from "../../../utils/Notify"


export default class BaseTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      loading: true,
      data: [],
      pagesNo: 0,
      searchTerm: "",
      csvData: [],
      searchFilter: this.props.searchFilter
    }

    this.reactTable = React.createRef();
    this.csvLink = React.createRef();
  }

  refresh(){
    let current = this.reactTable.current
    if(current){
      this._fetchData(current.state, current)
    }
  }

  _handleSearch = General.debounce(() => {
    let current = this.reactTable.current
    this.refresh()
  }, 500)


  _getUrl(endpoint, state){

    let params = {
      ...this.props.params,
      page_size: state.pageSize,
      page: state.page + 1,
      pagination_type: "page",
      search_term: this.state.searchTerm
    }

    let sorted = state.sorted[0]
    if(sorted){
      let orderBy = sorted.id
      orderBy = orderBy.replace(/\./g, "__")
      if(sorted.desc){
        orderBy = `-${orderBy}`
      }
      params["order_by"] = orderBy
    }

    if(this.state.searchFilter){
      params[this.props.searchFilterName] = this.state.searchFilter
    }

    return this._addParams(endpoint, params)
  }

  _fetchData(state, instance) {
    this.setState({ loading: true });
    let url = this._getUrl(this.props.endpoint, state)

    if(this.props.endpoint === ""){
      console.log("000000",  this.props.mockData())

      this.setState({
        data: this.props.mockData(),
        pagesNo: 1,
        loading: false
      });
      return
    }
    FetchHelper.get(url, false)
    .then(response => {
      console.log("dddd",  Math.ceil(response.count / state.pageSize))
      this.setState({
        data: response.results,
        pagesNo: Math.ceil(response.count / state.pageSize),
        totalRecords: response.count,
        loading: false
      });
    })
    .catch(error => {
      console.log("Error", error)
    })
  }

  _addParams(url, params){
    if(Object.keys(params).length == 0){
      return url
    }

    // TODO: switch to an actual url helper here to avoid bugs/edge cases
    if(url.indexOf("?") == -1){
      url += "?"
    }
    else if(!url.endsWith("&")){
      url += "&"
    }

    Object.keys(params).forEach(function(key) {
        url += key+"="+params[key]+"&";
    });

    // remove last '&'
    url = url.slice(0, -1);
    return url
  }

  _exportPressed(){
    if(this.state.exporting){
      return
    }

    let exportableColumns = this.props.columns.filter(column => column.exportable != false)

    let prefetchRelated = []
    let columns = exportableColumns.map(column => {
      let data = {
        name: column.Header,
        accessor: column.id || column.accessor,
        type: column.type,
        format: column.format
      }

      data.accessor.replace(/\./g, "__")
      if(data.accessor.indexOf('__') !== -1){
        prefetchRelated.push(data.accessor)
      }
      return data
    })

    let data = {
      export: true,
      options: encodeURIComponent(JSON.stringify({
        columns,
        prefetch_related: prefetchRelated,
        select_related: [],
      })),
      search_term: this.state.searchTerm,
      ...this.props.exportParams
    }

    if(this.state.searchFilter){
      data[this.props.searchFilterName] = this.state.searchFilter
    }

    if(this.props.defaultSorted && this.props.defaultSorted.length > 0){
      data.order_by = this.props.defaultSorted[0].id.replace(/\./g, "__")
      if(this.props.defaultSorted[0].desc){
        data.order_by = "-"+data.order_by
      }
    }

    let endpoint = this._addParams(this.props.endpoint, data)

    this.setState({ exporting: true })
    FetchHelper.download(endpoint, `${this.props.title}.csv`)
    .then(() => {
      this.setState({ exporting: false })
    })
    .catch(error => {
      Notify.error(error.message)
    })
  }

  _renderHeaderLeftContent(){
    return(
      <>
        <div class="col">
          <div class="search kt-input-icon kt-input-icon--left">
            <input
              type="text"
              class="form-control"
              placeholder={this.props.searchPlaceholder}
              onChange={e => {
                this.setState({
                  searchTerm: e.target.value
                }, () => this._handleSearch())
              }}
            />
            <span class="kt-input-icon__icon kt-input-icon__icon--left">
              <span><i class="la la-search"></i></span>
            </span>
          </div>
        </div>
        { this.props.filters &&
          <div class="col">
            <div class="kt-form__control">
              <div class="status">
                <Select
                  value={this.props.filters.find(filter => {
                    if(this.state.searchFilter){
                      return filter.value === this.state.searchFilter
                    }
                    return this.props.filters[0].value
                  })}
                  className="async-select-paginate-filter"
                  classNamePrefix="async-select-paginate-filter"
                  onChange={option => {
                    this.setState({
                      searchFilter: option.value
                    }, () => this._handleSearch())
                  }}
                  options={this.props.filters}
                />
              </div>
            </div>
          </div>
        }
      </>
    )
  }

  _renderExportButtons(){
    if(!this.props.exportButtonsEnabled){
      return null
    }

    return (
      <>
        <div class="row">
          <div className="col-md-12 text-right">
            <a
              className="btn btn-secondary"
              onClick={() => this._exportPressed()}
            >
              Export to CSV
            </a>
          </div>
        </div>
      </>
    )
  }

  render() {
    const { data, pagesNo, loading, totalRecords } = this.state;
    return (
      <Card
        title={this.props.title}
        icon={this.props.icon}
        renderToolbar={this.props.renderToolbar}
        loading={loading}
      >
        {/* remove c-base-table class based on prop flag on/off */}
        <div className="c-base-table">
          <div class="row action-bar">
            <div class="col-md-6">
              <div class="dataTables_filter">
                <div class="row">
                  { this._renderHeaderLeftContent()}
                </div>
              </div>
            </div>
            <div class="col-md-6 pull-right">
              <div class="row">
                <div class="col">
                  { this.props.renderHeaderRightContent() }
                </div>
                <div class="col-md-4">
                  { this._renderExportButtons() }
                </div>
              </div>
            </div>
          </div>
          <ReactTable
            ref={this.reactTable}
            manual
            data={data}
            pages={pagesNo}
            totalRecords={totalRecords}
            loading={loading}
            LoadingComponent={Loading}
            onFetchData={this._fetchData.bind(this)}
            columns={this.props.columns}
            getTdProps={this.props.getTdProps}
            getTrProps={this.props.getTrProps}
            getTheadProps={this.props.getTheadProps}
            getTheadThProps={this.props.getTheadThProps}
            showPagination={this.props.showPagination}
            showPaginationTop={this.props.showPaginationTop}
            showPaginationBottom={this.props.showPaginationBottom}
            defaultSorted={this.props.defaultSorted}
            SubComponent={this.props.SubComponent}
            NoDataComponent={() => null}
          />
        </div>
      </Card>
    )
  }
}

BaseTable.defaultProps = {
  params: {},
  label: "Search:",
  searchPlaceholder: "Search...",
  icon: null,
  filters: null,
  searchFilter: null,
  searchFilterName: "name",
  showPagination: true,
  showPaginationTop: false,
  showPaginationBottom: true,
  exportButtonsEnabled: true,
  getTdProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        paddingLeft: 10
      }
    }
  },
  getTrProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        paddingTop: 10,
        paddingBottom: 10
      }
    }
  },
  getTheadProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        boxShadow: '0 1px 1px 0 rgba(0,0,0,0.1)',
        paddingTop: 10,
        paddingBottom: 10,
        textAlign: 'left'
      }
    }
  },
  getTheadThProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        textAlign: 'left',
        paddingLeft: 10
      }
    }
  },
  SubComponent: null,
  renderHeaderRightContent: () => null,
  renderToolbar: () => null,
  exportParams: {}
}

class Loading extends React.Component {
  render () {
    return (
      this.props.loading
        ? <div className='-loading -active'>
            <div className='-loading-inner'>
            </div>
          </div>
        : null
    )
  }
}
