import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Switch from 'react-switch'

import Select from '../common/Select'
import Notes from '../Notes'
import FormAnswer from '../crm/FormAnswer'

import Backend from '../../../utils/Backend'
import AuthManager from '../../../utils/AuthManager'
import Website from '../../../utils/Website'
import Notify from '../../../utils/Notify'
import Submission from '../../../utils/Submission'
import Email from "../../../utils/Email";

export default class SubmissionModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = this._getState(props)
  }

  componentDidMount(){
    this._load()
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps), () => this._load())
  }

  _getState(props){
    return {
      loading: false,
      show: props.show,
      submissionId: props.submissionId,
      list: props.list,
      submission: {answers:[]},
    }
  }

  _load(){
    let { submissionId } = this.state

    if(!submissionId){
      this._loadLeadQuestions()
      return
    }

    this.setState({ loading: true })
    Backend.getSubmission(submissionId)
    .then(submission => {
      this.setState({ loading: false, submission })
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false})
    })
  }

  _loadLeadQuestions(){
    let { list, submission } = this.state

    Backend.getATSForm().then(form => {
      form.form_fields.map(row => {
        row.map(field => {
          submission.answers.push({
            field,
            options_selected: [],
          })
        })
      })
      submission.status = list
      submission.form = form.id
      this.setState({submission})
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  _onAnswerUpdated(value, index){
    let { submission } = this.state
    submission.answers[index] = value
    submission.answers[index].error = false
    this.setState({submission})
  }

  _renderAnswers(submission){
    if(!submission){
      return null
    }

    return submission.answers.map((answer, index) => {
      return (
        <div className='mt-5'>
          <FormAnswer
            answer={answer}
            formField={answer.field}
            onChange={(value) => this._onAnswerUpdated(value, index)}
          />
        </div>
      )
    })
  }

  _validateForm(submission){

    let valid = true

    submission.answers.map(answer => {

      if(answer.field.type === "boolean" && answer.field.is_required){
        answer.bool = false
      }

      if(answer.field.is_required){
        if(answer.field.type === "single_select"){
          if(!answer.option_selected){
            answer.error = true
            valid = false
          }
        }else if(answer.field.type === "multi_select"){
          if(answer.options_selected.length === 0){
            answer.error = true
            valid = false
          }
        }else if(answer.field.type === "boolean"){
          if(answer.bool === undefined){
            answer.error = true
            valid = false
          }
        }else if(answer.field.type === "file" || answer.field.type === "image"){
          if(!answer.file && !answer.image){
            answer.error = true
            valid = false
          }
        }
        else{
          if(!answer.value){
            answer.error = true
            valid = false
          }
        }
      }

      if(answer.field.type === "email" || answer.field.type === "contact_email"){
        if(!Email.isValid(answer.value)){
          answer.error = true
          valid = false
        }
      }

      if(answer.field.type === "phone" || answer.field.type === "contact_phone"){
        if(!answer.value || answer.value.length < 6){
          answer.error = true
          valid = false
        }
      }
    })

    this.setState({submission})

    return valid
  }

  _onSave(){
    let { submission } = this.state

    this.setState({ loading: true })
    let valid = this._validateForm(submission)

    if(valid){
      this._handleBackendCall(submission)
        .then(submission => {
          this.props.onUpdated(submission)
          let message = submission.id ? "Submission Updated!" : "Submission Created!"
          Notify.success(message)
          this.setState({ loading: false, submission: null })
        }).catch(error => {
        Notify.error(error.message)
        this.setState({ loading: false })
      })
    }else{
      this.setState({ loading: false })
    }
  }

  _handleBackendCall(submission){
    if(submission?.id){
      return Backend.updateSubmission(submission)
    }
    else{
      return Backend.createSubmission(submission)
    }
  }

  render() {
    let {
      show,
      submission,
      loading
    } = this.state

    let title = "Add New Lead"

    if(submission.data){
      title = submission.data.contact_first_name + ' ' + submission.data.contact_last_name
    }

    return (
      <Modal
        show={show}
        size="lg"
        onHide={() => {
          if (!loading) {
            this.props.onHide()
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this._renderAnswers(submission)}
          {submission.id && <Notes submission={submission}/>}
        </Modal.Body>

        <Modal.Footer>

          {submission.data?.contact_email &&
            <button
              type="button"
              onClick={() =>
                window.location.href='mailto:' + submission.data.contact_email
              }
              className="btn btn-secondary mw-100"
              disabled={loading}
            >
              Email
            </button>
          }

          {submission.data?.contact_phone &&
            <button
              type="button"
              onClick={() =>
                window.location.href='tel:' + submission.data.contact_phone
              }
              className="btn btn-secondary mw-100"
              disabled={loading}
            >
              Call
            </button>
          }

          <button
            type="button"
            className="btn btn-primary mw-100"
            disabled={loading}
            onClick={() => this._onSave()}
          >
            {submission?.id == null ? 'Add Lead' : 'Save' }
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
