
export default class Submission {
  static getAnswerValue(answer){
    if(answer.option_selected){
      return answer.option_selected.text
    }
    else if(answer.options_selected.length > 0){
      return answer.options_selected.map(option => option.text).join(', ')
    }
    else if(answer.bool != null){
      return answer.bool ? "Yes" : "No"
    }
    else if(answer.value_from){
      return `${answer.value_from} - ${answer.value_to}`
    }
    else if(answer.file){
      return answer.file.url
    }
    console.log("))))", answer)
    return answer.value
  }
}
