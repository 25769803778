import React from 'react';

const LeftRight = ({ className }) => {
    return (
        <svg className={className} width="648" height="379" viewBox="0 0 648 379" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2316_656)">
                <rect width="648" height="379" rx="20" fill="#404040" />
                <path d="M98.7508 194.917L65.0336 235.166C53.9294 248.421 63.3538 268.61 80.6455 268.61H243.71C259.357 268.61 269.159 251.699 261.382 238.121L222.94 171.013C216.027 158.945 199.32 157.203 190.065 167.585L163.941 196.892C156.735 204.976 144.462 206.007 136.009 199.238L127.092 192.098C118.45 185.177 105.861 186.429 98.7508 194.917Z" fill="#818181" />
                <circle opacity="0.18" cx="58.5" cy="60.5" r="175.5" fill="#37DB70" />
                <circle cx="58.5" cy="60.5" r="38.5" fill="#37DB70" />
                <rect width="372" height="379" transform="translate(319)" fill="#F4F0EC" />
                <path d="M368 122H483" stroke="#404040" stroke-width="15" stroke-linecap="round" />
                <path d="M368 156H550" stroke="#B7B7B7" stroke-width="10" stroke-linecap="round" />
                <path d="M455 179H511" stroke="#B7B7B7" stroke-width="10" stroke-linecap="round" />
                <path d="M368 179H432" stroke="#B7B7B7" stroke-width="10" stroke-linecap="round" />
                <rect x="362" y="220" width="114.673" height="36.3791" rx="7.9085" fill="#37DB70" />
                <path d="M394.425 237.794H443.458" stroke="white" stroke-width="7.9085" stroke-linecap="round" />
                <rect x="489.327" y="220" width="114.673" height="36.3791" rx="7.9085" fill="#767676" />
                <path d="M521.752 237.794H570.784" stroke="white" stroke-width="7.9085" stroke-linecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_2316_656">
                    <rect width="648" height="379" rx="20" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default LeftRight;