import React from 'react';

const ListView = ({ className }) => {
  return (
    <svg className={className} width="648" height="379" viewBox="0 0 648 379" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="648" height="379" rx="20" fill="#F4F0EC"/>
        <rect x="21" y="21" width="606" height="338" rx="16" fill="white"/>
        <rect x="59" y="52" width="530" height="85" rx="10" fill="#F7F7F7"/>
        <path d="M88 84H156" stroke="#404040" stroke-width="10" stroke-linecap="round"/>
        <path d="M88 106H203" stroke="#B7B7B7" stroke-width="10" stroke-linecap="round"/>
        <rect x="457" y="76" width="111" height="39" rx="10" fill="#37DB70"/>
        <path d="M491 96H537" stroke="white" stroke-width="8" stroke-linecap="round"/>
        <rect x="59" y="150" width="530" height="85" rx="10" fill="#F7F7F7"/>
        <path d="M88 182H156" stroke="#404040" stroke-width="10" stroke-linecap="round"/>
        <path d="M88 204H203" stroke="#B7B7B7" stroke-width="10" stroke-linecap="round"/>
        <rect x="457" y="174" width="111" height="39" rx="10" fill="#37DB70"/>
        <path d="M491 194H537" stroke="white" stroke-width="8" stroke-linecap="round"/>
        <rect x="59" y="248" width="530" height="85" rx="10" fill="#F7F7F7"/>
        <path d="M88 280H156" stroke="#404040" stroke-width="10" stroke-linecap="round"/>
        <path d="M88 302H203" stroke="#B7B7B7" stroke-width="10" stroke-linecap="round"/>
        <rect x="457" y="272" width="111" height="39" rx="10" fill="#37DB70"/>
        <path d="M491 292H537" stroke="white" stroke-width="8" stroke-linecap="round"/>
    </svg>
  )
}

export default ListView;
