import React from 'react'

import { Route, Switch, withRouter } from 'react-router-dom'

import Div from "./components/layouts/Div"

import ActivateShopForm from './components/product/ActivateShopForm'
import ActivateShopEngagementCard from './components/cards/ActivateShopEngagementCard'

import Aside from './components/Aside'

import BusinessInfo from './shop/BusinessInfo'
import BankDetails from './shop/BankDetails'
import StripeDetails from './shop/StripeDetails'
import Settings from './shop/Settings'
import CartSettings from './shop/CartSettings'
import ShippingSettings from './shop/ShippingSettings'

import Backend from "../utils/Backend"
import AuthManager from "../utils/AuthManager"
import Website from "../utils/Website"
import Notify from "../utils/Notify"

const TABS = [
  {
    title:"General Shop Settings",
    subtitle:"Configure your currency and other shop settings",
    icon:"settings",
    url: "/shop/settings"
  },
  {
    title:"Stock Settings",
    subtitle:"Configure your customers cart settings",
    icon:"bag",
    url: "/shop/cart"
  },
  {
    title:"Shipping Locations",
    subtitle:"Configure what countries you ship to",
    icon:"paper-plane",
    url: "/shop/shipping"
  }
]

class ShopSettings extends React.Component {
  constructor(props){
    super(props)

    let tabs = [...TABS]

    if(AuthManager.currentUser.company.stripe_account_type === "standard"){
      tabs.push({
        title:"Bank Details",
        subtitle:"Update your Bank Details",
        icon:"piggy-bank",
        url: "/shop/stripe"
      })
    }
    else{
      tabs = [
        ...tabs,
        {
          title:"Business Information",
          subtitle:"Update your business info",
          icon:"suitcase",
          url: "/shop/info"
        },
        {
          title:"Bank Details",
          subtitle:"Update your Bank Details",
          icon:"piggy-bank",
          url: "/shop/bank"
        }
      ]
    }

    this.state = {
      tabs,
      loading: false,
      activeTabIndex: this._getActiveTabIndex(tabs),
      showActivateShopEngagementCard: true,
      standardConnect: AuthManager.currentUser.company.stripe_account_type === "standard"
    }

    this.activateShopForm = React.createRef()
  }

  componentDidMount(){
    this._load()

    const query = new URLSearchParams(this.props.location.search);
    let status = query.get('status')
    if(status === "success"){
      Notify.success("Your Business Info has been successfully updated")
    }
    else if(status === "failure"){
      Notify.error("Your Business Info has not bbeen updated, please try again")
    }
  }

  _load(){
    this.setState({ loading: true })
    Backend.getShop(AuthManager.currentWebsite)
    .then(shop => {
      this.setState({
        shop,
        loading: false
      })
    })
    .catch(error => {
      //Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  _getActiveTabIndex(tabs){
    const { pathname } = this.props.location;
    for(var i=0; i < tabs.length; i++){
      let tab = tabs[i]
      if(pathname === tab.url){
        return i
      }
    }
    return 0
  }

  _handleCreateShop(){
    this.setState({ loadingShop: true })

    this.activateShopForm.current.save()
    .then(response => {
      window.location = "/products"
    })
    .catch(error => {
      this.setState({ loadingShop: false })
      Notify.error(error.message)
    })
  }

  _renderActivateShop(){
    let {
      loadingShop,
      showActivateShopEngagementCard
    } = this.state

    if(showActivateShopEngagementCard){
      return (
        <div className="row">
          <ActivateShopEngagementCard
            onCtaPressed={() => this.setState({ showActivateShopEngagementCard: false })}
          />
        </div>
      )
    }
    return (
      <div className="row">
          <ActivateShopForm
            ref={this.activateShopForm}
            title="Start Selling"
            confirmButtonText="Connect Bank Account"
            onConfirmPressed={() => this._handleCreateShop()}
          />
      </div>
    )
  }

  _renderSettings(){
    let {
      shop,
      tabs,
      activeTabIndex,
      standardConnect,
    } = this.state

    return (
      <div className="kt-portlet">
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2" data-ktwizard-state="step-first">

            <Aside
              tabs={tabs}
              activeTabIndex={activeTabIndex}
              onClick={(tab, index) => {
                this.setState({
                  activeTabIndex: index
                }, () =>{
                  this.props.history.push(tab.url)
                })
              }}
            />

            <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
              <form className="kt-form w-100" id="kt_form">

                <Switch>
                  <Route
                    exact
                    path="/shop/settings"
                    render={(props) => {
                      return (
                        <Settings shop={shop} {...props}/>
                      )
                    }}
                  />
                  <Route
                    exact
                    path="/shop/cart"
                    render={(props) => {
                      return (
                        <CartSettings
                          {...props}
                          shop={shop}
                          onUpdated={shop => this.setState({ shop })}
                        />
                      )
                    }}
                  />
                  <Route
                    exact
                    path="/shop/shipping"
                    render={(props) => {
                      return (
                        <ShippingSettings
                          {...props}
                          shop={shop}
                          onUpdated={shop => this.setState({ shop })}
                        />
                      )
                    }}
                  />
                  { !standardConnect &&
                    <>
                      <Route exact path="/shop/info" component={BusinessInfo} />
                      <Route exact path="/shop/bank" component={BankDetails} />
                    </>
                  }
                  {
                    standardConnect &&
                    <Route exact path="/shop/stripe" component={StripeDetails} />
                  }
                </Switch>

              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let {
      shop,
      loading
    } = this.state

    if(loading){
      return null
    }

    if(shop){
      return this._renderSettings()
    }
    else{
      return this._renderActivateShop()
    }
  }
}

export default withRouter(ShopSettings);
