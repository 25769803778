import React from 'react';

import FormRow from "./FormRow";
import General from "../../../utils/General";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import FormSettingsModal from "../modal/FormSettingsModal";
import FormDropzone from "./FormDropzone";

const FIELDS = [
  {
    uuid: General.uuid(),
    title: "Contact First Name",
    type: "contact_first_name",
    is_required: true,
    is_unique: false,
  },
  {
    uuid: General.uuid(),
    title: "Contact Last Name",
    type: "contact_last_name",
    is_required: true,
    is_unique: false,
  },
  {
    uuid: General.uuid(),
    title: "Contact Email",
    type: "contact_email",
    is_required: true,
    is_unique: false,
    send_emails: false,
  },
  {
    uuid: General.uuid(),
    title: "Contact Phone",
    type: "contact_phone",
    is_required: true,
    is_unique: false,
  },
  {
    uuid: General.uuid(),
    title: "Text Input",
    type: "text",
    is_required: true,
    is_unique: false,
  },
  {
    uuid: General.uuid(),
    title: "Text Area",
    type: "textarea",
    is_required: true,
    is_unique: false,
  },
  {
    uuid: General.uuid(),
    title: "Email",
    type: "email",
    is_required: true,
    is_unique: false,
    send_emails: false,
  },
  {
    uuid: General.uuid(),
    title: "Phone",
    type: "phone",
    is_required: true,
    is_unique: false,
  },
  {
    uuid: General.uuid(),
    title: "Number",
    type: "number",
    is_required: true,
    is_unique: false,
  },
  {
    uuid: General.uuid(),
    title: "Date",
    type: "date",
    is_required: true,
    is_unique: false,
  },
  // {
  //   uuid: General.uuid(),
  //   title: "DateTime",
  //   type: "datetime",
  //   is_required: true,
  //   is_unique: false,
  // },
  // {
  //   uuid: General.uuid(),
  //   title: "Time",
  //   type: "time",
  //   is_required: true,
  //   is_unique: false,
  // },
  {
    uuid: General.uuid(),
    title: "Radio Button (Single Select)",
    type: "single_select",
    alignment: "horizontal",
    is_required: true,
    is_unique: false,
    options: [{text: 'Option 01'}, {text: 'Option 02'}]
  },
  {
    uuid: General.uuid(),
    title: "Checkbox (Multi Select)",
    type: "multi_select",
    is_required: true,
    is_unique: false,
    options: [{text: 'Option 01'}, {text: 'Option 02'}]
  },
  {
    uuid: General.uuid(),
    title: "Checkbox (Single Select)",
    type: "boolean",
    is_required: false,
    is_unique: false,
  },
  {
    uuid: General.uuid(),
    title: "Dropdown (Single Select)",
    type: "single_select",
    alignment: "vertical",
    is_required: true,
    is_unique: false,
    options: [{text: 'Option 01'}, {text: 'Option 02'}]
  },
  {
    uuid: General.uuid(),
    title: "Opt In Checkbox",
    type: "boolean",
    is_required: true,
    url: "",
    error_message: ""
  },
  {
    uuid: General.uuid(),
    title: "File",
    type: "file",
    is_required: true,
  },
  {
    uuid: General.uuid(),
    title: "Image",
    type: "image",
    is_required: true,
  }
]

export default class Form extends React.Component  {

  constructor(props){
    super(props)
    this.state = {
      fields: FIELDS,
      form: props.form,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _validateForm(form){
    let contactFirstName = 0, contactLastName = 0, contactEmail = 0, contactPhone = 0;

    form.form_fields.map(formRow => {
      formRow.map(formField => {

        if(formField.type === "contact_first_name"){
          contactFirstName += 1
        }

        if(formField.type === "contact_last_name"){
          contactLastName += 1
        }

        if(formField.type === "contact_email"){
          contactEmail += 1
        }

        if(formField.type === "contact_phone"){
          contactPhone += 1
        }

      })
    })

    let error = null

    if(contactFirstName > 1){
      error = "Please include only one Contact First Name field"
    }else if(contactLastName > 1){
      error = "Please include only one Contact Last Name field"
    }else if(contactEmail > 1){
      error = "Please include only one Contact Email field"
    }else if(contactPhone > 1){
      error = "Please include only one Contact Phone field"
    }

    if(form.type === "crm"){
      if(contactFirstName === 0){
        error = "The form must include at least one Contact First Name"
      }else if(contactLastName === 0){
        error = "The form must include at least one Contact Last Name"
      }else if(contactEmail === 0){
        error = "The form must include at least one Contact Email"
      }
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _handleSaveForm(){
    let{
      form,
    } = this.state

    if(!this._validateForm(form)){
      return
    }

    this.setState({loading: true})

    Backend.updateForm(form).then(response => {
      this.setState({loading: false})
      Notify.success("Form Updated")
    }).catch(error => {
      this.setState({loading: false})
      Notify.error(error.message)
    })
  }

  _renderField(fields, formField, index, notDraggable=false){
    let className = ''
    if(formField.type.includes("contact")){
      className = 'contact-items'
    }
    let pointerEvent = !notDraggable ? "auto" : "none"
    return(
      <>
        <div
          className={`btn btn-block mb-3 text-left ${className}`}
          draggable={true}
          style={{pointerEvents: pointerEvent}}
          onDragStart={e => {
            e.dataTransfer.setData("text/plain", JSON.stringify({index:index}))
            e.dataTransfer.dropEffect = "copy"
            this.setState({
              showDropzones: true
            })
          }}
          onDragEnd={e => {
            this.setState({
              showDropzones: false
            })
          }}
        >
          {formField.title}
        </div>
      </>
    )
  }

  _renderRow(row, rowIndex){
    let {
      form,
      showDropzones,
    } = this.state

    return (
      <FormRow
        form={form}
        formRow={row}
        rowIndex={rowIndex}
        showDropzones={showDropzones}
        selectedFormFields={form.form_fields}
        onUpdate={(row, index, formField) => {
          form.form_fields[row][index] = formField
          this.setState({ form })
        }}
        onShowDropzones={show => {
          this.setState({
            showDropzones: show
          })
        }}
        onAddField={(formFieldObject, destinationIndex, placeIndex, position) => {
          let formFieldIndex = formFieldObject.index
          let destIndex = position === "row" ? destinationIndex : rowIndex
          if(formFieldObject.hasOwnProperty('row')) {
            let formFieldRow = formFieldObject.row
            form.form_fields = moveField(
              form.form_fields[formFieldRow],
              form.form_fields,
              formFieldIndex,
              destinationIndex,
              formFieldRow,
              placeIndex,
              position
            )
          }else{
            form.form_fields = addField(
              this.state.fields,
              form.form_fields,
              formFieldIndex,
              destIndex,
              placeIndex,
              position
            )
          }
          this.setState({ form, showDropzones: false })
        }}
        onRemoveItem={(rowIndex, index) => {
          if(form.form_fields[rowIndex].length === 1){
            form.form_fields.splice(rowIndex, 1)
          }else{
            form.form_fields[rowIndex].splice(index, 1)
          }
          this.setState({ form })
        }}
      />
    )
  };

  render(){
    let{
      form,
      fields,
      loading,
      showDropzones,
      showFormSettingsModal
    } = this.state

    if(!form) return null

    let fixedFields = Array.from(fields).splice(0, 4)
    let variableFields = Array.from(fields).splice(4)

    return (
      <>

        { /* starts, column fields */}
        <div className={"col-lg-4 col-xl-4 fields"}>
          <div className={"kt-portlet kt-portlet--tabs kt-portlet--height-fluid"}>
            <div className={"kt-portlet__head"}>
              <div className={"kt-portlet__head-label"}>
                <h3 className="kt-portlet__head-title">Available Form Fields</h3>
              </div>
            </div>
            <div className={"kt-portlet__body"}>
              <p>Drag & Drop any form field over onto the form, into a single or double column.</p>
              <div className={"container"}>
                {fixedFields.map((field, index) => {
                  let notDraggable = form.form_fields.find(formRow => {
                    return formRow.find(formField => formField.type === field.type)
                  }) != null
                  return this._renderField(fields, field, index, notDraggable)
                })}
                <div className="separator separator-dashed separator-border-4 mb-4 mt-4"/>
                {variableFields.map((field, index) => this._renderField(fields, field, index+4))}
              </div>
            </div>
          </div>
        </div>
        { /* end, column fields */}

        { /* starts, column new form */}
        <div className={"col-lg-8 col-xl-8 new-form"}>
          <div className={"kt-portlet kt-portlet--tabs kt-portlet--height-fluid"}>
            <div className={"kt-portlet__head"}>
              <div className={"kt-portlet__head-label"}>
                <h3 className="kt-portlet__head-title">{form.name}</h3>
              </div>
              <div className={"kt-portlet__head-toolbar"}>
                <div className="kt-portlet__head-wrapper">
                  <a
                    href="javascript:void(0);"
                    className="btn btn-primary font-weight-bolder mr-2"
                    onClick={() => {
                      this.setState({ showFormSettingsModal: true })
                    }}
                  >
                    <i className="fa fa-cog text-white"/>
                    Form Settings
                  </a>
                </div>
              </div>
            </div>
            <div className={"kt-portlet__body"}>
              {
                form.form_fields.length > 0 ?
                form.form_fields.map((formField, index) => this._renderRow(formField, index)) :
                <FormDropzone
                  position={"row"}
                  rowIndex={0}
                  placeIndex={undefined}
                  showDropzones={showDropzones}
                  onAddField={(formFieldObject, destinationIndex, placeIndex, position) => {
                    let formFieldIndex = formFieldObject.index
                    form.form_fields = addField(
                      this.state.fields,
                      form.form_fields,
                      formFieldIndex,
                      destinationIndex,
                      placeIndex,
                      position
                    )
                    this.setState({form})
                  }}
                />
              }
              <div className={"row"}>
                <button
                  type="button"
                  className="btn btn-primary font-weight-bolder mt-2 kt-ml-30"
                >
                  {form.submit_text}
                </button>
              </div>
            </div>
            <div className="kt-portlet__foot">
              <div className="kt-form__actions pull-right">
                <button
                  type="button"
                  className="btn btn-primary font-weight-bolder"
                  disabled={loading}
                  onClick={e => {
                    this._handleSaveForm()
                  }}
                >
                  <i className="fa fa-check text-white"/>
                  Save Form
                </button>
              </div>
            </div>
          </div>
        </div>
        { /* end, column new form */}

        {
          showFormSettingsModal &&
          <FormSettingsModal
            form={form}
            show={showFormSettingsModal}
            updateFormSettings={form => {
              this.setState({form, showFormSettingsModal: false})
            }}
            onHide={() => this.setState({ showFormSettingsModal: false })}
          />
        }

      </>
    )

  }
};

const addField = (source, destination, sourceIndex, destinationIndex, placeIndex, position) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const item = sourceClone[sourceIndex];

  if(position === "column"){
    destClone[destinationIndex].splice(placeIndex, 0, { ...item, uuid: General.uuid() });
  }else if(position === "row"){
    destClone.splice(destinationIndex, 0, [{ ...item, uuid: General.uuid() }]);
  }

  return destClone;
};

const moveField = (source, destination, sourceIndex, destinationIndex, sourceRow, placeIndex, position) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(sourceIndex, 1);

  if(sourceClone.length === 0){
    destClone.splice(sourceRow, 1)
    if(destinationIndex > sourceRow){
      destinationIndex -= 1
    }
  }else{
    destClone[sourceRow] = sourceClone
  }

  if(position === "column"){
    destClone[destinationIndex].splice(placeIndex, 0, removed);
  }else if(position === "row"){
    destClone.splice(destinationIndex, 0, [removed]);
  }

  return destClone;
};
