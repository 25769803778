import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from 'moment'

import EngagementCard from './EngagementCard'

import AuthManager from '../../../utils/AuthManager'

export default class DashboardEngagementCard extends React.Component {

  _showActivateShopButton(){
    return !AuthManager.currentUser.company.payments_connected
  }

  _showSelectDomainButton(){
    return !AuthManager.currentWebsite.domain
  }

  _showAddArticleButton(){
    return AuthManager.currentWebsite.articles_no === 0
  }

  render(){

    if(!this._showActivateShopButton() && !this._showSelectDomainButton()){
      return null
    }
    return (
      <EngagementCard
        { ...this.props }
        title="Welcome To Your Amply Dashboard"
        renderContent={() => `Your dashboard will enable you to control the backend of your careers website, such as reviewing stats, creating new blog content and enabling new Add-Ons!`}
        cta={() => {
          return (
            <div className="c-btn-group">
              <a
                href={"javascript:void(0);"}
                className="btn btn-success font-weight-bold py-2 mr-3"
                onClick={() => this.props.onManageATSPressed()}
              >
                Manage ATS
              </a>
            </div>
          )
        }}
        contentContainerClassName="col-md-9"
      />
    )
  }
}
