import React from 'react'

import IconLogoPositionLeft from '../pages/components/icons/login/v2/logo-position/Left'
import IconLogoPositionCenter from '../pages/components/icons/login/v2/logo-position/Center'

import IconMainBannerStyleFullHeight from '../pages/components/icons/login/v2/main-banner-style/FullHeight'
import IconMainBannerStyleHalfHeight from '../pages/components/icons/login/v2/main-banner-style/HalfHeight'
import IconMainBannerStyleLeftRight from '../pages/components/icons/login/v2/main-banner-style/LeftRight'

import IconJobStyleGrid from '../pages/components/icons/login/v2/job-style/GridView'
import IconJobStyleList from '../pages/components/icons/login/v2/job-style/ListView'

import extractDomain from 'extract-domain';

const LOREM_IPSUM = "Hover and click into this text to change it your own text to suit your website. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus lacus in lacus blandit placerat. Quisque et orci quis ex interdum ultricies."

export const BUTTON_STYLES = [
  { text: "Circle", css: "circle", value: "rounded" },
  { text: "Square", css: "square",  value: "square"},
  { text: "Rounded", css: "rounded", value: "standard" },
]

export const FONTS = [
  { text: "Modern", css: "montserrat", value: "Montserrat" },
  { text: "Handwritten", css: "sacramento", value: "Sacramento" },
  { text: "Code", css: "roboto-mono", value: "Roboto Mono" },
]

export const LOGO_POSITIONS = [
  { image: <IconLogoPositionLeft className={"w-100 h-auto"} />, text: "Logo Left Aligned", css: "", value: "left" },
  { image: <IconLogoPositionCenter className={"w-100 h-auto"} />, text: "Logo Centre Aligned", css: "", value: "center"}
]

export const BANNER_STYLES = [
  { image: <IconMainBannerStyleFullHeight className={"w-100 h-auto"} />, text: "Full Banner Image", css: "", value: "full" },
  { image: <IconMainBannerStyleHalfHeight className={"w-100 h-auto"} />, text: "Half Banner Image", css: "", value: "half" },
  { image: <IconMainBannerStyleLeftRight className={"w-100 h-auto"} />, text: "Image Left & Text Right", css: "", value: "image_left" }
]

export const JOB_STYLES = [
  { image: <IconJobStyleGrid className={"w-100 h-auto"} />, text: "Card View", css: "", value: "grid" },
  { image: <IconJobStyleList className={"w-100 h-auto"} />, text: "List View", css: "", value: "list" },
]

export const BLOCKS = [
  { icon: 'contact_page', title: "CV Upload", key: "crm" },
  { icon: 'play_circle', title: "CEO Video", key: "video" },
  { icon: 'collections_bookmark', title: "Office Gallery", key: "gallery" },
  { icon: 'place', title: "Office Location", key: "map" },
  { icon: 'people', title: "People / Team Photos", key: "team" },
  { icon: 'handshake', title: "Partners / Clients", key: "partners" },
  { icon: 'star', title: "Employee Testimonials", key: "testimonials" },
  { icon: 'balance', title: "Company Values", key: "values" },
]

export const ADD_ONS = [
  { icon: 'newspaper', title: "Blog", key: "blog" },
  { icon: 'question_answer', title: "Live Chat", key: "chat" },
  { icon: 'contact_page', title: "ATS", key: "crm" },
  { icon: 'folder', title: "Media Bank", key: "files" },
  { icon: 'smartphone', title: "Mobile App", key: "mobile_app" },
  { icon: 'people', title: "Team", key: "team" },
  { icon: 'analytics', title: "Visitors & Analytics", key: "visitors" },
]

export default class SiteBuilder {
  constructor(data) {
    this.data = data
  }

  getBaseDetails(){
    let {
      first_name,
      last_name,
      company_name,
      email,
      phone_number,
      password,
      premium,
      source,
      referrer,
      slug,
      image,
      color,
      font,
      blocks,
      pkg,
      buttonStyle,
      logoPosition,
      bannerStyle,
      selectedCompany
    } = this.data

    font = {
      weight: 900,
      family_name: font,
    }

    let transparentNav = bannerStyle !== "image_left"
    let secondaryLogoColor = transparentNav ? "#ffffff": color
    let secondaryNavBackgroundColor = transparentNav ?  "transparent" : "#ffffff"

    let menuLogos = {}
    if(selectedCompany.image_logo){
      let logoUrl = selectedCompany.image_logo
      let logoImage = {
        path: logoUrl,
        type: "photo",
        banner: logoUrl,
        favicon: logoUrl,
        original: logoUrl,
        thumbnail: logoUrl,
      }
      menuLogos = {
        logo_custom: false,
        logo_size: 50,
        primary_image: logoImage,
        secondary_image: logoImage
      }
    }
    else{
      menuLogos = {
        logo_custom: true,
        primary_logo: {
          font: font,
          icon: "icon-Atom",
          icon_color: color,
          style: "icon_left_text_right",
          text: company_name,
          text_color: color
        },
        secondary_logo: {
          font: font,
          icon: "icon-Atom",
          icon_color: secondaryLogoColor,
          style: "icon_left_text_right",
          text: company_name,
          text_color: secondaryLogoColor,
        }
      }
    }


    return {
      source,
      referrer,
      demo: false,
      name: company_name,
      user: {
        first_name: first_name,
        last_name: "Last Name",
        email: email,
        password: password
      },
      homepage: {
        title: company_name
      },
      extra: {
        company: selectedCompany,
      },
      primary_color: color,
      menu: {
        nav_items: [
        ],
        settings: {
          msc_enabled: true,
          primary_color: "#333333",
          secondary_color: transparentNav ? "#ffffff" : "#333333",
          tertiary_color: "#ffffff",
          quaternary_color: secondaryNavBackgroundColor
        },
        primary_button: {
          title: "Jobs",
          url: "/jobs",
          settings: {
            primary_color: "#ffffff",
            quaternary_color: null,
            quinary_color: color,
            secondary_color: color,
            senary_color: color,
            tertiary_color: color,
          },
          badge_settings: {}
        },
        secondary_button: null,
        logo_position: logoPosition,
        logo_size: 22,
        style: "bar",
        ...menuLogos
      },
      footer: {
        fine_print: window.General.Branding.Name,
        title: company_name,
        settings: {
          msc_enabled: true,
          primary_color: "#ffffff",
          secondary_color: "#ffffff",
          senary_color: null,
          tertiary_color: color
        }
      },
      hide_footer: false,
      hide_menu: false,
      h1_font: {
        ...font,
        weight: 600,
      },
      h2_font: {
        ...font,
        weight: 400,
      },
      button_style: buttonStyle,
      subscription:  {
        package: pkg.id,
        add_ons: pkg.add_ons
      }
    }
  }

  getContentBlocks(){
    let blocksData = []

    blocksData =  [
      this.bannerBlock(this.data), // #41: Main Banner and #15 - Full Height Image Left Text Right
      this.titleBlock(this.data), // #11 - Medium Title Text
      this.jobsBlock(this.data), // #11 - Medium Title Text
      //this.featuresBlock(this.data), // #10 - 3 Icon Boxes
      this.textBlock(this.data) // #9 - Info & Description
    ]

    if(this.blocksInclude("gallery")){ // #24 - Small Slider Gallery
      blocksData = [
        ...blocksData,
        this.galleryBlock(this.data)
      ]
    }

    if(this.blocksInclude("video")){ // #52 - Video Block
      blocksData = [
        ...blocksData,
        this.videoBlock(this.data)
      ]
    }

    if(this.blocksInclude("values")){
      blocksData = [
        ...blocksData,
        this.valuesBlock(this.data) // #46 - Values Block
      ]
    }


    if(this.blocksInclude("crm")){ // #42 - CRM Form
      blocksData = [
        ...blocksData,
        this.formBlock(this.data)
      ]
    }

    if(this.blocksInclude("partners")){
      blocksData = [
        ...blocksData,
        this.partnersBlock(this.data) // #55 - Logo Carousel
      ]
    }


    if(this.blocksInclude("team")){
      blocksData = [
        ...blocksData,
        this.teamBlock(this.data) // #25 - Circle Team
      ]
    }


    if(this.blocksInclude("testimonials")){
      blocksData = [
        ...blocksData,
        this.testimonialsBlock(this.data) // #49 - Testimonials
      ]
    }

    if(this.blocksInclude("map")){ // #34 - Map Fullscreen
      blocksData = [
        ...blocksData,
        this.mapBlock(this.data)
      ]
    }

    return blocksData
  }

  bannerBlock(data){
    let { bannerStyle, image, color, company_name } = data
    let blockId = 41
    let height = "full"
    let title = company_name
    let subtitle = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus lacus in lacus blandit placerat. Quisque et orci quis ex interdum ultricies."
    let body = null
    let backgroundImage = {
      ...image,
      type: "photo",
    }

    let fontColor = "#ffffff"
    let foregroundImage = null
    let overlay = 6

    if(bannerStyle === "half"){
      height = "half"
    }
    else if(bannerStyle === "image_left"){
      blockId = 15
      subtitle = null
      body = "Hover and click into this text to change it your own text to suit your website. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hover and click into this text to change it your own text to suit your website. Lorem ipsum dolor sit amet, consectetur adipiscing elit."
      backgroundImage = null
      foregroundImage = {
        ...image,
        type: "photo",
      }
      fontColor = "#333333"
      overlay = null
    }
    let blockData = {
      height,
      title,
      subtitle,
      title_color: fontColor,
      subtitle_color: fontColor,
      body,
      anchor: "banner",
      block_id: blockId,
      gradient: false,
      background_image: backgroundImage,
      foreground_image: foregroundImage,
      primary_button: null,
      secondary_button: null,
      link_button: null,
      extra: {
        search_bar: true
      },
      settings: {
        overlay,
        msc_enabled: true,
        primary_color: fontColor,
        quinary_color: null,
        secondary_color: fontColor
      }
    }

    return blockData
  }

  titleBlock(data){
    return {
      anchor: "title",
      title: "About Us",
      subtitle: LOREM_IPSUM,
      title_color: "#000000",
      subtitle_color: "#333333",
      block_id: 11,
      settings: {
        primary_color: "#000000",
        secondary_color: "#333333",
        tertiary_color: "#ffffff",
      }
    }
  }

  featuresBlock(data){
    return {
      name: "3 Icon Boxes",
      block_id: 10,
      anchor: "section_10",
      title_color: "#252525",
      subtitle_color: "#666666",
      settings: {
        primary_color: "#252525",
        tertiary_color: "#ffffff",
        secondary_color: "#666666",
        quaternary_color: null,
      },
      icon_infos: [
        {
          id: 67,
          icon: "icon icon--lg icon-Air-Balloon",
          title: "Title 1 Text",
          settings: {

          },
          subtitle: "Hover and click into this text to change it your own text to suit your website.",
          button: null
        },
        {
          icon: "icon icon--lg icon-Speaker-2",
          title: "Title 2 Text",
          settings: {

          },
          subtitle: "Hover and click into this text to change it your own text to suit your website.",
          button: null
        },
        {
          icon: "icon icon--lg icon-Astronaut",
          title: "Title 3 Text",
          settings: {

          },
          subtitle: "Hover and click into this text to change it your own text to suit your website.",
          button: null
        }
      ],
    }
  }

  textBlock(data){
    return {
      body: "Hover and click into this text to change it your own text to suit your website. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus lacus in lacus blandit placerat. Quisque et orci quis ex interdum ultricies.\nHover and click into this text to change it your own text to suit your website. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus lacus in lacus blandit placerat. Quisque et orci quis ex interdum ultricies.",
      name: "Info & Description",
      title: "Our Mission",
      title_color: "#ffffff",
      anchor: "section_9",
      block_id: 9,
      settings: {
        primary_color: "#ffffff",
        tertiary_color: data.color,
        secondary_color: "#ffffff",
        animation_duration: 0
      },
      text_infos: [
        {
          title: "Contact",
          settings: {

          },
          subtitle: "James Murphy"
        },
        {
          title: "Phone",
          settings: {

          },
          subtitle: "+353 1 2345678"
        },
        {
          title: "Email",
          settings: {

          },
          subtitle: "james@website.com"
        }
      ],
    }
  }

  galleryBlock(data){


    let imageUrls = [
      "https://images.unsplash.com/photo-1555396273-367ea4eb4db5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2560&h=1600",
      "https://images.unsplash.com/photo-1498654896293-37aacf113fd9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2560&h=1600",
      "https://images.unsplash.com/photo-1508424757105-b6d5ad9329d0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2560&h=1600",
      "https://images.unsplash.com/photo-1485182708500-e8f1f318ba72?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2560&h=1600"
    ]

    let images = imageUrls.map(imageUrl => {
      return {
        path: imageUrl,
        type: "photo",
        banner: imageUrl,
        favicon: imageUrl,
        original: imageUrl,
        thumbnail: imageUrl,
      }
    })

    return {
      name: "Our Offices",
      title: "Our Offices",
      subtitle: null,
      title_color: "#666666",
      subtitle_color: "#666666",
      anchor: "gallery",
      block_id: 24,
      settings: {
          primary_color: "#ffffff",
          tertiary_color: "#ffffff",
          secondary_color: "#ffffff",
      },
      primary_button: null,
      images: images,
    }
  }

  mapBlock(data){
    return {
      anchor: "form",
      title: null,
      subtitle: null,
      block_id: 34,
      settings: {
        primary_color: "#333333",
        secondary_color: "#333333",
        senary_color: data.color,
        tertiary_color: "#ffffff"
      }
    }
  }

  formBlock(data){
    return {
      anchor: "form",
      title: "Send Your CV",
      subtitle: "Please complete the form below and we'll get back to you soon.",
      title_color: "#333333",
      subtitle_color: "#333333",
      block_id: 42,
      settings: {
        primary_color: "#333333",
        secondary_color: "#333333",
        senary_color: data.color,
        tertiary_color: "#ffffff"
      }
    }
  }

  teamBlock(data){
    return {
      name: "Team",
      block_id: 25,
      gradient: null,
      title:"Meet Our Team",
      title_color: "#252525",
      subtitle_color: "#808080",
      settings: {
        primary_color: "#252525",
        tertiary_color: "#ffffff",
        secondary_color: "#808080",
        padding_top: 0
      },
      image_infos: [
        {
          image: {
            path: "https://images.unsplash.com/photo-1504593811423-6dd665756598?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            type: "photo",
            banner: "https://images.unsplash.com/photo-1504593811423-6dd665756598?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=800&h=450",
            favicon: "https://images.unsplash.com/photo-1504593811423-6dd665756598?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            original: "https://images.unsplash.com/photo-1504593811423-6dd665756598?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            thumbnail: "https://images.unsplash.com/photo-1504593811423-6dd665756598?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=400&h=225",
          },
          title: "James Murphy",
          settings: {

          },
          subtitle: "Sales"
        },
        {
          image: {
            path: "https://images.unsplash.com/photo-1554652297-6e7a24cf8fde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            type: "photo",
            banner: "https://images.unsplash.com/photo-1554652297-6e7a24cf8fde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=800&h=450",
            favicon: "https://images.unsplash.com/photo-1554652297-6e7a24cf8fde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            original: "https://images.unsplash.com/photo-1554652297-6e7a24cf8fde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            thumbnail: "https://images.unsplash.com/photo-1554652297-6e7a24cf8fde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=400&h=225",
          },
          title: "Jane Townsend",
          settings: {

          },
          subtitle: "Design"
        },
        {
          image: {
            path: "https://images.unsplash.com/photo-1533621834623-d0b25d0b14e7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            type: "photo",
            banner: "https://images.unsplash.com/photo-1533621834623-d0b25d0b14e7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=800&h=450",
            favicon: "https://images.unsplash.com/photo-1533621834623-d0b25d0b14e7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            original: "https://images.unsplash.com/photo-1533621834623-d0b25d0b14e7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            thumbnail: "https://images.unsplash.com/photo-1533621834623-d0b25d0b14e7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=400&h=225",
          },
          title: "Bernard Smith",
          settings: {

          },
          subtitle: "Operations"
        },
        {
          image: {
            path: "https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            type: "photo",
            banner: "https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=800&h=450",
            favicon: "https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            original: "https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            thumbnail: "https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=400&h=225",
          },
          title: "Jake Butler",
          settings: {

          },
          subtitle: "Product"
        },
        {
          image: {
            path: "https://images.unsplash.com/photo-1500301111609-42f1aa6df72a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            type: "photo",
            banner: "https://images.unsplash.com/photo-1500301111609-42f1aa6df72a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=800&h=450",
            favicon: "https://images.unsplash.com/photo-1500301111609-42f1aa6df72a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            original: "https://images.unsplash.com/photo-1500301111609-42f1aa6df72a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            thumbnail: "https://images.unsplash.com/photo-1500301111609-42f1aa6df72a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=400&h=225",
          },
          title: "Sylvia O Rourke",
          button: null,
          settings: {

          },
          subtitle: "Marketing"
        },
        {
          image: {
            path: "https://images.unsplash.com/photo-1469334031218-e382a71b716b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            type: "photo",
            banner: "https://images.unsplash.com/photo-1469334031218-e382a71b716b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=800&h=450",
            favicon: "https://images.unsplash.com/photo-1469334031218-e382a71b716b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            original: "https://images.unsplash.com/photo-1469334031218-e382a71b716b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=2000&h=2000",
            thumbnail: "https://images.unsplash.com/photo-1469334031218-e382a71b716b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzMjEyOX0&fit=max&w=400&h=225",
          },
          title: "Sarah Johnson",
          button: null,
          settings: {

          },
          subtitle: "Growth"
        }
      ],
    }
  }

  testimonialsBlock(data){
    return {
      name: "Testimonials",
      block_id: 49,
      title:"What Our Employees Say",
      title_color: "#444444",
      subtitle_color: "#444444",
      settings: {
        primary_color: "#444444",
        tertiary_color: "#ffffff",
        secondary_color: "#444444",
        quaternary_color: "#3d85c6",
        padding_top: 0
      },
      text_infos: [
        {
          body: "One of the best companies that I've ever worked for.",
          title: "James Murphy",
          settings: {

          },
          subtitle: "Senior Engineer"
        },
        {
          body: "Great people who are very passionate about their work.",
          title: "Jason Briggs",
          settings: {

          },
          subtitle: "Accountant"
        },
        {
          body: "Feels like a second family. Everyone is very friendly and nice.",
          title: "Sarah Johnson",
          settings: {

          },
          subtitle: "Project Manager"
        }
      ],
    }
  }

  partnersBlock(data){
    return {
      name: "Logo Carousel",
      block_id: 55,
      title:"Our Clients & Partners",
      title_color: "#252525",
      subtitle_color: "#808080",
      settings: {
        primary_color: "#252525",
        tertiary_color: "#ffffff",
        secondary_color: "#808080",
        padding_top: 0
      },
      image_infos: [
        {
          image: {
            path: "/companies/1/websites/images/original_image_59a2c103-d01e-419a-b1ba-97f5b3697e54.png",
            type: "photo",
            banner: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/banner_image_59a2c103-d01e-419a-b1ba-97f5b3697e54.png",
            original: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/original_image_59a2c103-d01e-419a-b1ba-97f5b3697e54.png",
            thumbnail: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/thumbnail_image_59a2c103-d01e-419a-b1ba-97f5b3697e54.png",
          },
          title: "Title goes here",
          settings: {

          },
          subtitle: "Subtitle goes here"
        },
        {
          image: {
            path: "/companies/1/websites/images/original_image_bc7d03f9-88d9-4fe5-8363-dbbb1ebf20a2.png",
            type: "photo",
            banner: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/banner_image_bc7d03f9-88d9-4fe5-8363-dbbb1ebf20a2.png",
            original: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/original_image_bc7d03f9-88d9-4fe5-8363-dbbb1ebf20a2.png",
            thumbnail: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/thumbnail_image_bc7d03f9-88d9-4fe5-8363-dbbb1ebf20a2.png",
          },
          title: "Title goes here",
          settings: {

          },
          subtitle: "Subtitle goes here"
        },
        {
          image: {
            path: "/companies/1/websites/images/original_image_8597ac83-7c8d-4c44-bfaa-a22744ab3145.png",
            type: "photo",
            banner: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/banner_image_8597ac83-7c8d-4c44-bfaa-a22744ab3145.png",
            original: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/original_image_8597ac83-7c8d-4c44-bfaa-a22744ab3145.png",
            thumbnail: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/thumbnail_image_8597ac83-7c8d-4c44-bfaa-a22744ab3145.png",
          },
          title: "Title goes here",
          settings: {

          },
          subtitle: "Subtitle goes here"
        },
        {
          image: {
            path: "/companies/1/websites/images/original_image_cab5c388-7706-4d65-a9d6-6bfcaec6e0d5.png",
            type: "photo",
            banner: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/banner_image_cab5c388-7706-4d65-a9d6-6bfcaec6e0d5.png",
            original: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/original_image_cab5c388-7706-4d65-a9d6-6bfcaec6e0d5.png",
            thumbnail: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/thumbnail_image_cab5c388-7706-4d65-a9d6-6bfcaec6e0d5.png",
          },
          title: "Title goes here",
          settings: {

          },
          subtitle: "Subtitle goes here"
        },
        {
          image: {
            path: "/companies/1/websites/images/original_image_e1300b46-8495-4292-a50e-a4c8adef0c36.png",
            type: "photo",
            banner: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/banner_image_e1300b46-8495-4292-a50e-a4c8adef0c36.png",
            original: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/original_image_e1300b46-8495-4292-a50e-a4c8adef0c36.png",
            thumbnail: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/thumbnail_image_e1300b46-8495-4292-a50e-a4c8adef0c36.png",
          },
          title: "Title goes here",
          settings: {
          },
          subtitle: "Subtitle goes here"
        },
        {
          image: {
            path: "/companies/1/websites/images/original_image_56f1d764-fad5-4114-87f3-dd6a7648f289.png",
            type: "photo",
            banner: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/banner_image_56f1d764-fad5-4114-87f3-dd6a7648f289.png",
            original: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/original_image_56f1d764-fad5-4114-87f3-dd6a7648f289.png",
            thumbnail: "https://d2tbwlwytqydh4.cloudfront.net/companies/1/websites/images/thumbnail_image_56f1d764-fad5-4114-87f3-dd6a7648f289.png",
          },
          title: "Title goes here",
          settings: {

          },
          subtitle: "Subtitle goes here"
        }
      ],
    }
  }

  videoBlock(data){
    return {
      title: "Words From Our CEO",
      subtitle: "Hover and click into this text to change it your own text to suit your website.",
      title_color: "#333333",
      subtitle_color: "#333333",
      body: "Hover and click into this text to change it your own text to suit your website.",
      block_id: 52,
      background_video: {
        url: "https://www.youtube.com/watch?v=Xn8tufsbSz0"
      },
      settings: {
        primary_color: "#333333",
        secondary_color: "#333333",
        senary_color: data.color,
        tertiary_color: "#ffffff"
      }
    }
  }

  valuesBlock(data) {
    return {
      title: "Our Values",
      icon_infos: [
        {
          settings: {
            animation_duration: 0,
          },
          icon: "icon icon--lg icon-Air-Balloon",
          title: "Title 1 Text",
          subtitle: "Hover and click into this text to change it your own text to suit your website. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus lacus in lacus blandit placerat. Quisque et orci quis ex interdum ultricies.",
        },
        {
          settings: {
            animation_duration: 0,
          },
          icon: "icon icon--lg icon-Apple",
          title: "Title 2 Text",
          subtitle: "Hover and click into this text to change it your own text to suit your website. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus lacus in lacus blandit placerat. Quisque et orci quis ex interdum ultricies.",
        },
        {
          settings: {
            animation_duration: 0,
          },
          icon: "icon icon--lg icon-Speaker-2",
          title: "Title 3 Text",
          subtitle: "Hover and click into this text to change it your own text to suit your website. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus lacus in lacus blandit placerat. Quisque et orci quis ex interdum ultricies.",
        },
        {
          settings: {
            animation_duration: 0,
          },
          icon: "icon icon--lg icon-Astronaut",
          title: "Title 4 Text",
          subtitle: "Hover and click into this text to change it your own text to suit your website. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus faucibus lacus in lacus blandit placerat. Quisque et orci quis ex interdum ultricies.",
        }
      ],
      settings: {
        msc_enabled: true,
        primary_color: "#666666",
        secondary_color: "#666666",
        tertiary_color: "#FFF",
        quaternary_color: "#444444",
        animation_duration: 0,
      },
      block_id: 46,
      title_color: "#666666",
      subtitle_color: "#666666",
    }
  }

  jobsBlock(data) {
    return {
      title: "Latest Jobs",
      subtitle: "",
      variation: data.jobStyle,
      settings: {
        msc_enabled: true,
        primary_color: "#252525",
        secondary_color: "#808080",
        tertiary_color: "#FFF",
        animation_duration: 0,
      },
      block_id: 58,
      title_color: "#252525",
      subtitle_color: "#808080",
      parallax: false,
    }
  }

  getProgress(){
    let {
      first_name,
      email,
      phone_number,
      agree,
      source,
      password,
      company_name,
      image,
      color,
      font,
      buttonStyle,
      logoPosition,
      bannerStyle,
      jobStyle,
      selectedCompany
    } = this.data
    let progress = 20

    let values = [
        color,
        font,
        image,
        buttonStyle,
        logoPosition,
        bannerStyle
    ]

    for(var i =  0; i < values.length; i++){
      let value = values[i]
      if(value){
        progress += 10
      }
    }

    let details = [
      first_name,
      email,
      password,
      company_name,
    ]

    for(var i =  0; i < details.length; i++){
      let detail = details[i]
      if(detail){
        progress += 2
      }
    }

    if(jobStyle){
      progress += 5
    }

    if(selectedCompany){
      progress += 5
    }

    if(agree){
      progress += 2
    }

    return progress
  }

  blocksInclude(key){
    let { blocks } = this.data
    return blocks.findIndex(o => o === key) > -1
  }
}
