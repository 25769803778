import React from "react"

import ImageEdit from "../common/ImageEdit"

import Select from '../common/Select';

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Notify from "../../../utils/Notify"

const STATUSES = [
  {
    label: "Yes",
    value: true
  },
  {
    label: "No",
    value: false
  }
]

export default class SecuritySettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      website: { ...AuthManager.currentWebsite }
    }
  }

  _update(e){
    let {
      website
    } = this.state

    e.preventDefault()

    if(!website.password && website.has_password){
      Notify.error("Please enter a password")
      return
    }

    let data = {
      password: website.password || null
    }

    this.setState({ loading: true })
    Backend.updateWebsite(website, data)
    .then(website => {
      this.setState({
        website,
        loading: false,
      })
      Notify.success("Updated password successfully")
    })
    .catch(error => {
      this.setState({
        loading: false
      })
      Notify.error(error.message)
    })
  }

  _handleChange(e) {
    let website = this.state.website
    website[e.target.name] = e.target.value
    this.setState({ website, error: null })
  }

  render() {
    let {
      website,
      loading
    } = this.state

    return (
      <>
        <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
          <div className="kt-heading kt-heading--md">Security Settings</div>
          <div className="kt-form__section kt-form__section--first">
            <div className="kt-wizard-v2__form">

              <div className="form-group row">
                <label className="col-lg-3 col-form-label">Password Enabled</label>
                <div className="col-lg-9 my-auto">
                  <Select
                    value={STATUSES.find(status => status.value === website.has_password )}
                    className="async-select-paginate"
                    classNamePrefix="async-select-paginate"
                    name="status"
                    onChange={option => {
                      website.has_password = option.value
                      if(!website.has_password === false){
                        website.password = null
                      }
                      this.setState({ website })
                    }}
                    options={STATUSES}
                  />
                </div>
              </div>

              { website.has_password &&
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">Password</label>
                  <div className="col-lg-9 my-auto">
                    <input
                      type="password"
                      className="form-control form-control-lg"
                      autoComplete="new-password"
                      name="password"
                      value={ website.password }
                      onChange={e => this._handleChange(e)}
                    />
                    <span>Enter a new password to overwrite the existing password</span>
                  </div>
                </div>
              }

            </div>
          </div>
        </div>
        <div className="kt-form__actions">
          <button
            className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
            disabled={loading}
            onClick={(e) => this._update(e)}
          >
            Save Changes
          </button>
        </div>
      </>
    )
  }
}
