import React from 'react'

import AuthManager from '../utils/AuthManager'

import ATSEngagementCard from './components/cards/ATSEngagementCard'
import ATSBoard from './components/board/ATSBoard'


export default class MobileATS extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  render() {

    if (false && AuthManager.currentWebsite.leads_no === 0) {
      return (
        <div className="row">
          <ATSEngagementCard />
        </div>
      )
    }

    return (
      <div className='mobile-crm'>
        <ATSBoard/>
      </div>
    )
  }
}
