import React from "react";
import { withRouter } from "react-router-dom";

import WebsiteSelect from "./common/WebsiteSelect";

import AuthManager from "../../utils/AuthManager";
import Backend from "../../utils/Backend";
import Website from "../../utils/Website";
import Event from "../../utils/Event";
import General from "../../utils/General";

const BUTTON_VIEW_SITE = {
  title: "View / Edit Site",
  type: "button",
};

const BUTTON_START_SELLING = {
  title: "Manage Shop",
  type: "button",
};

const LINK_DASHBOARD = {
  title: "Dashboard",
  url: "/",
  type: "link",
  icon: (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon id="Bound" points="0 0 24 0 24 24 0 24" />
      <path
        d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
        id="Shape"
        fill="#000000"
        fillRule="nonzero"
      />
      <path
        d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
        id="Path"
        fill="#000000"
        opacity="0.3"
      />
    </g>
  ),
};

const LINK_INVITES = {
  title: "Guests",
  url: "/guests",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24" />
      <path
        d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
        fill="#000000"
        fillRule="nonzero"
        opacity="0.3"
      />
      <path
        d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
        fill="#000000"
        fillRule="nonzero"
      />
    </g>
  ),
};

const LINK_MESSAGES = {
  title: "Emails / Messages",
  url: "/emails",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,12 C19,12.5522847 18.5522847,13 18,13 L6,13 C5.44771525,13 5,12.5522847 5,12 L5,3 C5,2.44771525 5.44771525,2 6,2 Z M7.5,5 C7.22385763,5 7,5.22385763 7,5.5 C7,5.77614237 7.22385763,6 7.5,6 L13.5,6 C13.7761424,6 14,5.77614237 14,5.5 C14,5.22385763 13.7761424,5 13.5,5 L7.5,5 Z M7.5,7 C7.22385763,7 7,7.22385763 7,7.5 C7,7.77614237 7.22385763,8 7.5,8 L10.5,8 C10.7761424,8 11,7.77614237 11,7.5 C11,7.22385763 10.7761424,7 10.5,7 L7.5,7 Z"
        fill="#000000"
        opacity="0.3"
      />
      <path
        d="M3.79274528,6.57253826 L12,12.5 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 Z"
        fill="#000000"
      />
    </g>
  ),
};

const LINK_CONTACTS = {
  title: "Submissions",
  url: "/leads",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,12 C19,12.5522847 18.5522847,13 18,13 L6,13 C5.44771525,13 5,12.5522847 5,12 L5,3 C5,2.44771525 5.44771525,2 6,2 Z M7.5,5 C7.22385763,5 7,5.22385763 7,5.5 C7,5.77614237 7.22385763,6 7.5,6 L13.5,6 C13.7761424,6 14,5.77614237 14,5.5 C14,5.22385763 13.7761424,5 13.5,5 L7.5,5 Z M7.5,7 C7.22385763,7 7,7.22385763 7,7.5 C7,7.77614237 7.22385763,8 7.5,8 L10.5,8 C10.7761424,8 11,7.77614237 11,7.5 C11,7.22385763 10.7761424,7 10.5,7 L7.5,7 Z"
        fill="#000000"
        opacity="0.3"
      />
      <path
        d="M3.79274528,6.57253826 L12,12.5 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 Z"
        fill="#000000"
      />
    </g>
  ),
  addOnKey: "crm"
};

const LINK_CONTACT_SETTINGS = {
  title: "ATS Settings",
  url: "/leads/settings",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <rect
        fill="#000000"
        opacity="0.3"
        x="2"
        y="6"
        width="21"
        height="12"
        rx="6"
      />
      <circle fill="#000000" cx="17" cy="12" r="4" />
    </g>
  ),
  addOnKey: "crm"
};

const LINK_ARTICLES = {
  title: "Articles",
  url: "/articles",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,12 C19,12.5522847 18.5522847,13 18,13 L6,13 C5.44771525,13 5,12.5522847 5,12 L5,3 C5,2.44771525 5.44771525,2 6,2 Z M7.5,5 C7.22385763,5 7,5.22385763 7,5.5 C7,5.77614237 7.22385763,6 7.5,6 L13.5,6 C13.7761424,6 14,5.77614237 14,5.5 C14,5.22385763 13.7761424,5 13.5,5 L7.5,5 Z M7.5,7 C7.22385763,7 7,7.22385763 7,7.5 C7,7.77614237 7.22385763,8 7.5,8 L10.5,8 C10.7761424,8 11,7.77614237 11,7.5 C11,7.22385763 10.7761424,7 10.5,7 L7.5,7 Z"
        fill="#000000"
        opacity="0.3"
      />
      <path
        d="M3.79274528,6.57253826 L12,12.5 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 Z"
        fill="#000000"
      />
    </g>
  ),
  addOnKey: "blog"
};

const LINK_ARTICLE_SETTINGS = {
  title: "Blog Settings",
  url: "/article/settings",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <rect
        fill="#000000"
        opacity="0.3"
        x="2"
        y="6"
        width="21"
        height="12"
        rx="6"
      />
      <circle fill="#000000" cx="17" cy="12" r="4" />
    </g>
  ),
  addOnKey: "blog"
};

const LINK_PAGES = {
  title: "Pages",
  url: "/pages",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M3,4 L20,4 C20.5522847,4 21,4.44771525 21,5 L21,7 C21,7.55228475 20.5522847,8 20,8 L3,8 C2.44771525,8 2,7.55228475 2,7 L2,5 C2,4.44771525 2.44771525,4 3,4 Z M3,10 L13,10 C13.5522847,10 14,10.4477153 14,11 L14,19 C14,19.5522847 13.5522847,20 13,20 L3,20 C2.44771525,20 2,19.5522847 2,19 L2,11 C2,10.4477153 2.44771525,10 3,10 Z"
        fill="#000000"
      />
      <rect
        fill="#000000"
        opacity="0.3"
        x="16"
        y="10"
        width="5"
        height="10"
        rx="1"
      />
    </g>
  ),
};

const LINK_SUBSCRIPTION = {
  title: "Manage Add-Ons",
  url: "/add-ons",
  type: "link",
  icon: <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"/>
            <path d="M3,4 L20,4 C20.5522847,4 21,4.44771525 21,5 L21,7 C21,7.55228475 20.5522847,8 20,8 L3,8 C2.44771525,8 2,7.55228475 2,7 L2,5 C2,4.44771525 2.44771525,4 3,4 Z M3,10 L13,10 C13.5522847,10 14,10.4477153 14,11 L14,19 C14,19.5522847 13.5522847,20 13,20 L3,20 C2.44771525,20 2,19.5522847 2,19 L2,11 C2,10.4477153 2.44771525,10 3,10 Z" fill="#000000"/>
            <rect fill="#000000" opacity="0.3" x="16" y="10" width="5" height="10" rx="1"/>
        </g>
}

const LINK_TEAM = {
  title: "Team",
  url: "/team",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
        fill="#000000"
        opacity="0.3"
      />
      <path
        d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z"
        fill="#000000"
        opacity="0.3"
      />
      <path
        d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z"
        fill="#000000"
        opacity="0.3"
      />
    </g>
  ),
  addOnKey: "team"
};

const LINK_GENERAL_SETTINGS = {
  title: "General Settings",
  url: "/settings",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <rect
        fill="#000000"
        opacity="0.3"
        x="2"
        y="6"
        width="21"
        height="12"
        rx="6"
      />
      <circle fill="#000000" cx="17" cy="12" r="4" />
    </g>
  ),
};

const LINK_PRODUCTS = {
  title: "Products",
  url: "/products",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M4,9.67471899 L10.880262,13.6470401 C10.9543486,13.689814 11.0320333,13.7207107 11.1111111,13.740321 L11.1111111,21.4444444 L4.49070127,17.526473 C4.18655139,17.3464765 4,17.0193034 4,16.6658832 L4,9.67471899 Z M20,9.56911707 L20,16.6658832 C20,17.0193034 19.8134486,17.3464765 19.5092987,17.526473 L12.8888889,21.4444444 L12.8888889,13.6728275 C12.9050191,13.6647696 12.9210067,13.6561758 12.9368301,13.6470401 L20,9.56911707 Z"
        fill="#000000"
      />
      <path
        d="M4.21611835,7.74669402 C4.30015839,7.64056877 4.40623188,7.55087574 4.5299008,7.48500698 L11.5299008,3.75665466 C11.8237589,3.60013944 12.1762411,3.60013944 12.4700992,3.75665466 L19.4700992,7.48500698 C19.5654307,7.53578262 19.6503066,7.60071528 19.7226939,7.67641889 L12.0479413,12.1074394 C11.9974761,12.1365754 11.9509488,12.1699127 11.9085461,12.2067543 C11.8661433,12.1699127 11.819616,12.1365754 11.7691509,12.1074394 L4.21611835,7.74669402 Z"
        fill="#000000"
        opacity="0.3"
      />
    </g>
  ),
  addOnKey: "shop"
};

const LINK_PRODUCT_ADD = {
  title: "Add Product",
  url: "/products/add",
  type: "link",
  icon: (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon id="Bound" points="0 0 24 0 24 24 0 24" />
      <path
        d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
        id="Shape"
        fill="#000000"
        fillRule="nonzero"
      />
      <path
        d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
        id="Path"
        fill="#000000"
        opacity="0.3"
      />
    </g>
  ),
  addOnKey: "shop"
};

const LINK_SALES = {
  title: "Sales",
  url: "/sales",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M18.5,6 C19.3284271,6 20,6.67157288 20,7.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 C17.6715729,20 17,19.3284271 17,18.5 L17,7.5 C17,6.67157288 17.6715729,6 18.5,6 Z M12.5,11 C13.3284271,11 14,11.6715729 14,12.5 L14,18.5 C14,19.3284271 13.3284271,20 12.5,20 C11.6715729,20 11,19.3284271 11,18.5 L11,12.5 C11,11.6715729 11.6715729,11 12.5,11 Z M6.5,15 C7.32842712,15 8,15.6715729 8,16.5 L8,18.5 C8,19.3284271 7.32842712,20 6.5,20 C5.67157288,20 5,19.3284271 5,18.5 L5,16.5 C5,15.6715729 5.67157288,15 6.5,15 Z"
        fill="#000000"
      />
    </g>
  ),
  addOnKey: "shop"
};

const LINK_SHOP_SETTINGS = {
  title: "Shop Settings",
  url: "/shop/settings",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <rect
        fill="#000000"
        opacity="0.3"
        x="2"
        y="6"
        width="21"
        height="12"
        rx="6"
      />
      <circle fill="#000000" cx="17" cy="12" r="4" />
    </g>
  ),
  addOnKey: "shop"
};

const LINK_SHOP_ACTIVATE = {
  title: "Start Selling",
  url: "/shop/activate",
  type: "link",
  icon: (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon id="Bound" points="0 0 24 0 24 24 0 24" />
      <path
        d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
        id="Shape"
        fill="#000000"
        fillRule="nonzero"
      />
      <path
        d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
        id="Path"
        fill="#000000"
        opacity="0.3"
      />
    </g>
  ),
  addOnKey: "shop"
};

const LINK_DOMAIN_HANDLING = {
  title: "Domain",
  url: "/domain/amply",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M5,6 L19,6 C19.5522847,6 20,6.44771525 20,7 L20,17 L4,17 L4,7 C4,6.44771525 4.44771525,6 5,6 Z"
        fill="#000000"
      />
      <rect
        fill="#000000"
        opacity="0.3"
        x="1"
        y="18"
        width="22"
        height="1"
        rx="0.5"
      />
    </g>
  ),
  addOnKey: "domains"
};

const LINK_LOGOUT = {
  title: "Log Out",
  url: "/account",
  type: "link",
};

const LINK_FORM_BUILDER = {
  title: "Form Builder",
  url: "/lead-form",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24" />
      <path
        d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z"
        fill="#000000"
        fill-rule="nonzero"
        opacity="0.3"
      />
      <rect fill="#000000" x="6" y="11" width="9" height="2" rx="1" />
      <rect fill="#000000" x="6" y="15" width="5" height="2" rx="1" />
    </g>
  ),
  addOnKey: "crm"
};

const LINK_COUPONS = {
  title: "Coupons",
  url: "/coupons",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <polygon
        fill="#000000"
        opacity="0.3"
        points="12 20.0218549 8.47346039 21.7286168 6.86905972 18.1543453 3.07048824 17.1949849 4.13894342 13.4256452 1.84573388 10.2490577 5.08710286 8.04836581 5.3722735 4.14091196 9.2698837 4.53859595 12 1.72861679 14.7301163 4.53859595 18.6277265 4.14091196 18.9128971 8.04836581 22.1542661 10.2490577 19.8610566 13.4256452 20.9295118 17.1949849 17.1309403 18.1543453 15.5265396 21.7286168"
      />
      <polygon
        fill="#000000"
        points="14.0890818 8.60255815 8.36079737 14.7014391 9.70868621 16.049328 15.4369707 9.950447"
      />
      <path
        d="M10.8543431,9.1753866 C10.8543431,10.1252593 10.085524,10.8938719 9.13585777,10.8938719 C8.18793881,10.8938719 7.41737243,10.1252593 7.41737243,9.1753866 C7.41737243,8.22551387 8.18793881,7.45690126 9.13585777,7.45690126 C10.085524,7.45690126 10.8543431,8.22551387 10.8543431,9.1753866"
        fill="#000000"
        opacity="0.3"
      />
      <path
        d="M14.8641422,16.6221564 C13.9162233,16.6221564 13.1456569,15.8535438 13.1456569,14.9036711 C13.1456569,13.9520555 13.9162233,13.1851857 14.8641422,13.1851857 C15.8138085,13.1851857 16.5826276,13.9520555 16.5826276,14.9036711 C16.5826276,15.8535438 15.8138085,16.6221564 14.8641422,16.6221564 Z"
        fill="#000000"
        opacity="0.3"
      />
    </g>
  ),
  addOnKey: "shop"
};

const LINK_FILES = {
  title: "Media Bank",
  url: "/files",
  type: "link",
  icon: (
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <rect x={0} y={0} width={24} height={24} />
      <path
        d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z"
        fill="#000000"
        fillRule="nonzero"
        opacity="0.3"
      />
      <rect
        fill="#000000"
        opacity="0.3"
        x={11}
        y={2}
        width={2}
        height={14}
        rx={1}
      />
      <path
        d="M12.0362375,3.37797611 L7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 C5.90236893,7.31658249 5.90236893,6.68341751 6.29289322,6.29289322 L11.2928932,1.29289322 C11.6689749,0.916811528 12.2736364,0.900910387 12.6689647,1.25670585 L17.6689647,5.75670585 C18.0794748,6.12616487 18.1127532,6.75845471 17.7432941,7.16896473 C17.3738351,7.57947475 16.7415453,7.61275317 16.3310353,7.24329415 L12.0362375,3.37797611 Z"
        fill="#000000"
        fillRule="nonzero"
      />
    </g>
  ),
  addOnKey: "files"
};

const LINK_CHATS = {
  title: "Chats",
  url: "/chats",
  type: "link",
  icon: (
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <rect x={0} y={0} width={24} height={24} />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4862 18L12.7975 21.0566C12.5304 21.54 11.922 21.7153 11.4386 21.4483C11.2977 21.3704 11.1777 21.2597 11.0887 21.1255L9.01653 18H5C3.34315 18 2 16.6569 2 15V6C2 4.34315 3.34315 3 5 3H19C20.6569 3 22 4.34315 22 6V15C22 16.6569 20.6569 18 19 18H14.4862Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H6C5.44772 9 5 8.55228 5 8C5 7.44772 5.44772 7 6 7ZM6 11H11C11.5523 11 12 11.4477 12 12C12 12.5523 11.5523 13 11 13H6C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11Z"
        fill="black"
      />
    </g>
  ),
  addOnKey: "chat"
};

const LINK_VISITORS = {
  title: "Visitors",
  url: "/visitors",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24" />
      <path
        d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
        fill="#000000"
        fill-rule="nonzero"
        opacity="0.3"
      />
      <path
        d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
        fill="#000000"
        fill-rule="nonzero"
      />
    </g>
  ),
  addOnKey: "visitors"
};
const LINK_ADMIN_CHATS = {
  title: "Personal Web Designer",
  url: "/chats/admin",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L5,18 C3.34314575,18 2,16.6568542 2,15 L2,6 C2,4.34314575 3.34314575,3 5,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 Z M6.16794971,10.5547002 C7.67758127,12.8191475 9.64566871,14 12,14 C14.3543313,14 16.3224187,12.8191475 17.8320503,10.5547002 C18.1384028,10.0951715 18.0142289,9.47430216 17.5547002,9.16794971 C17.0951715,8.86159725 16.4743022,8.98577112 16.1679497,9.4452998 C15.0109146,11.1808525 13.6456687,12 12,12 C10.3543313,12 8.9890854,11.1808525 7.83205029,9.4452998 C7.52569784,8.98577112 6.90482849,8.86159725 6.4452998,9.16794971 C5.98577112,9.47430216 5.86159725,10.0951715 6.16794971,10.5547002 Z"
        fill="#000000"
      />
    </g>
  ),
  addOnKey: "web_designer"
};

const LINK_CHAT_SETTINGS = {
  title: "Settings",
  url: "/chats/settings",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <rect
        fill="#000000"
        opacity="0.3"
        x="2"
        y="6"
        width="21"
        height="12"
        rx="6"
      />
      <circle fill="#000000" cx="17" cy="12" r="4" />
    </g>
  ),
  addOnKey: "chat"
};


const LINK_FORMS = {
  title: "Forms",
  url: "/forms",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000"/>
        <rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519) " x="16.3255682" y="2.94551858" width="3" height="18" rx="1"/>
    </g>
  ),
  addOnKey: "crm"
};

const LINK_SUBMISSIONS = {
  title: "Submissions",
  url: "/submissions",
  type: "link",
  icon: (
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <rect fill="#000000" opacity="0.3" x="4" y="4" width="4" height="4" rx="1"/>
        <path d="M5,10 L7,10 C7.55228475,10 8,10.4477153 8,11 L8,13 C8,13.5522847 7.55228475,14 7,14 L5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 L14,7 C14,7.55228475 13.5522847,8 13,8 L11,8 C10.4477153,8 10,7.55228475 10,7 L10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,10 L13,10 C13.5522847,10 14,10.4477153 14,11 L14,13 C14,13.5522847 13.5522847,14 13,14 L11,14 C10.4477153,14 10,13.5522847 10,13 L10,11 C10,10.4477153 10.4477153,10 11,10 Z M17,4 L19,4 C19.5522847,4 20,4.44771525 20,5 L20,7 C20,7.55228475 19.5522847,8 19,8 L17,8 C16.4477153,8 16,7.55228475 16,7 L16,5 C16,4.44771525 16.4477153,4 17,4 Z M17,10 L19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 L17,14 C16.4477153,14 16,13.5522847 16,13 L16,11 C16,10.4477153 16.4477153,10 17,10 Z M5,16 L7,16 C7.55228475,16 8,16.4477153 8,17 L8,19 C8,19.5522847 7.55228475,20 7,20 L5,20 C4.44771525,20 4,19.5522847 4,19 L4,17 C4,16.4477153 4.44771525,16 5,16 Z M11,16 L13,16 C13.5522847,16 14,16.4477153 14,17 L14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 L10,17 C10,16.4477153 10.4477153,16 11,16 Z M17,16 L19,16 C19.5522847,16 20,16.4477153 20,17 L20,19 C20,19.5522847 19.5522847,20 19,20 L17,20 C16.4477153,20 16,19.5522847 16,19 L16,17 C16,16.4477153 16.4477153,16 17,16 Z" fill="#000000"/>
    </g>
  ),
  addOnKey: "crm"
};

const BUTTONS = [BUTTON_VIEW_SITE];

const SECTIONS = [
  {
    title: null,
    links: [LINK_DASHBOARD, LINK_PAGES],
  },
  {
    title: "Visitors & Traffic",
    links: [LINK_VISITORS, LINK_CHATS, LINK_CHAT_SETTINGS],
  },
  {
    title: "News & Blog",
    links: [LINK_ARTICLES],
  },
  {
    title: "ATS",
    links: [LINK_CONTACTS, LINK_FORM_BUILDER, LINK_CONTACT_SETTINGS],
  },
  {
    title: "Forms",
    links: [LINK_FORMS, LINK_SUBMISSIONS],
  },
  {
    title: "Files",
    links: [LINK_FILES],
  },
  {
    title: "Admin",
    links: [
      LINK_DOMAIN_HANDLING,
      LINK_TEAM,
      LINK_GENERAL_SETTINGS
    ],
  },
];

export const MENU_ITEMS = [
  LINK_DASHBOARD,
  LINK_PAGES,
  LINK_SALES,
  LINK_PRODUCTS,
  LINK_PRODUCT_ADD,
  LINK_TEAM,
  LINK_GENERAL_SETTINGS,
  LINK_SHOP_SETTINGS,
  LINK_DOMAIN_HANDLING,
  LINK_SHOP_ACTIVATE,
  LINK_CONTACTS,
  LINK_CONTACT_SETTINGS,
  LINK_ARTICLES,
  LINK_ARTICLE_SETTINGS,
  LINK_FORM_BUILDER,
  LINK_FILES,
  LINK_CHATS,
  LINK_VISITORS,
  LINK_ADMIN_CHATS,
  LINK_CHAT_SETTINGS,
  LINK_SUBSCRIPTION,
  LINK_SUBMISSIONS,
  LINK_FORMS
];

class LeftMenu extends React.Component {
  constructor(props) {
    super(props);

    LINK_CONTACTS.title = window.General.Branding.CrmName;

    this.state = this._getState()
  }

  _getState(){
    let sections = [ ...SECTIONS ]
    sections = sections.map(section => {
      section.links = section.links.map(link => {
        return {...link}
      })
      return { ...section }
    })

    let paymentsConnected = AuthManager.currentUser.company.payments_connected;

    sections = this._pruneSectionsByAddOns(sections)

    return {
      sections,
      paymentsConnected,
      menuItems: MENU_ITEMS,
    };
  }

  _pruneSectionsByAddOns(sections){
    let { company } = AuthManager.currentUser
    let addOns = company.subscription.add_ons

    let prunedSections = sections.map(section => {
      section.links = section.links.map(link => {
        if(!link.addOnKey){
          return link
        }
        let addOn = addOns[link.addOnKey]
        if(addOn.visible && addOn.enabled){
          return link
        }
        return null
      })

      section.links = section.links.filter(link => link != null)
      return section
    })

    prunedSections = prunedSections.filter(section => section.links.length > 0)
    return prunedSections
  }

  componentDidMount() {
    this._updateActive();
    Event.on('SUBSCRIPTION_UPDATED', (data) => {
      this.setState(this._getState())
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this._updateActive();
    }
  }

  _updateActive() {
    let pathname = this.props.location.pathname;
    let menuItems = this.state.menuItems.map((link) => {
      link.active = link.url == pathname;
      return link;
    });
    this.setState({ menuItems });
  }

  _onLinkPressed(link) {
    if (link == LINK_LOGOUT) {
      AuthManager.logout();
    }
    this.props.history.push(link.url);
  }

  _handleEditSiteClicked(e) {
    e.preventDefault();
    this.setState({ loadingHash: true });
    let website = AuthManager.currentWebsite;

    let url = Website.getSiteUrl(website);
    url += "?edit=true";

    let editWebsiteTab = window.open();
    Backend.createLoginHash()
      .then((hash) => {
        editWebsiteTab.location.href = url + "&h=" + hash.value
        this.setState({ loadingHash: false });
      })
      .catch((error) => {
        window.open(url);
        this.setState({ loadingHash: false });
      });
  }

  _handleStartSelling(e) {
    let { paymentsConnected } = this.state;
    e.preventDefault();

    let url = paymentsConnected ? "/products" : "/shop/activate";
    this._goTo(url);
  }

  _goTo(url) {
    this.props.history.push(url);
  }

  _setAdminConversations() {
    let { sections } = this.state;

    Backend.getAdminConversations()
      .then((adminConversations) => {
        if (adminConversations.length > 0) {
          sections[1].links.splice(2, 0, LINK_ADMIN_CHATS);
        }
        this.setState({
          sections,
        });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  }

  _renderSections() {
    let { sections } = this.state;

    return (
      <div
        className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
        id="kt_aside_menu_wrapper"
      >
        <div
          id="kt_aside_menu"
          className="kt-aside-menu"
          data-ktmenu-vertical="1"
          data-ktmenu-scroll="1"
          data-ktmenu-dropdown-timeout="500"
        >
          <ul className="kt-menu__nav">
            {sections.map((section, sectionIndex) => {
              return (
                <>
                  {section.title && (
                    <li className="kt-menu__section ">
                      <h4 className="kt-menu__section-text">{section.title}</h4>
                      <i className="kt-menu__section-icon flaticon-more-v2"></i>
                    </li>
                  )}
                  {section.links.map((link, linkIndex) => {
                    let className = "kt-menu__item";
                    if (link.url === window.location.pathname) {
                      className += " kt-menu__item--active";
                    }
                    return (
                      <li className={className} aria-haspopup="true">
                        <a
                          className="kt-menu__link"
                          onClick={() => this.props.history.push(link.url)}
                        >
                          <span className="kt-menu__link-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                              className="kt-svg-icon"
                            >
                              {link.icon}
                            </svg>
                          </span>
                          <span className="kt-menu__link-text">
                            {link.title}
                          </span>
                        </a>
                      </li>
                    );
                  })}
                </>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }

  render() {
    let {
      buttons,
      menuItems,
      loadingHash,
      paymentsConnected,
      adminConversations,
    } = this.state;


    let { company } = AuthManager.currentUser
    let addOns = company.subscription.add_ons

    return (
      <>
        {/* <button className="kt-aside-close " id="kt_aside_close_btn">
          <i className="la la-close">
          </i>
        </button> */}
        <div
          className="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop"
          id="kt_aside"
        >
          <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand">
            <div className="kt-aside__brand-logo">
              <a onClick={() => this._goTo("/")} style={{ cursor: "pointer" }}>
                <img
                  alt="Logo"
                  src={window.General.Branding.Logo}
                  height="50px"
                />
              </a>
            </div>
            <div className="kt-aside__brand-tools">
              <button
                className="kt-aside__brand-aside-toggler"
                id="kt_aside_toggler"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    className="kt-svg-icon"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
                        id="Path-94"
                        fill="#000000"
                        fillRule="nonzero"
                        transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) "
                      />
                      <path
                        d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
                        id="Path-94"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                        transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) "
                      />
                    </g>
                  </svg>
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    className="kt-svg-icon"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                        id="Path-94"
                        fill="#000000"
                        fillRule="nonzero"
                      />
                      <path
                        d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                        id="Path-94"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                        transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                      />
                    </g>
                  </svg>
                </span>
              </button>
              {/*<button className="kt-aside__brand-aside-toggler kt-aside__brand-aside-toggler--left" id="kt_aside_toggler"><span></span></button> */}
            </div>
          </div>

          <div
            className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
            id="kt_aside_menu_wrapper"
          >
            <div
              id="kt_aside_menu"
              className="kt-aside-menu "
              data-ktmenu-vertical="1"
              data-ktmenu-scroll="1"
              data-ktmenu-dropdown-timeout="500"
            >
              <ul className="kt-menu__nav ">
                <div class="desktop-buttons">
                  <li class="kt-menu__item">
                    <button
                      href="#"
                      class="btn btn-brand side-margin-20 m-b-20"
                      onClick={(e) => this._handleEditSiteClicked(e)}
                      disabled={loadingHash}
                    >
                      View / Edit Careers Site
                    </button>
                  </li>
                  <li class="kt-menu__item">
                    <button
                      href="#"
                      class="btn btn-outline-brand side-margin-20 m-b-20"
                      onClick={(e) => this.props.history.push("/add-ons")}
                    >
                      Manage Add-Ons
                    </button>
                  </li>
                </div>
                <div class="mobile-buttons" style={{ display: "none" }}>
                  <li class="kt-menu__item">
                    <a
                      href="#"
                      class="btn btn-outline-brand btn-icon side-margin-20 m-b-20"
                      onClick={(e) => this._handleEditSiteClicked(e)}
                    >
                      <i class="fa fa-eye"></i>
                    </a>
                  </li>
                </div>

                {this._renderSections()}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(LeftMenu);
