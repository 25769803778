import React from "react"

import AsyncSelect from '../AsyncSelect'

import SubmissionActionsCell from "./cells/SubmissionActionsCell"
import SubmissionModal from "../modal/SubmissionModal";

import BaseTable from "./BaseTable"

import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import AuthManager from '../../../utils/AuthManager'
import Submission from '../../../utils/Submission'
import Notify from "../../../utils/Notify"

import moment from 'moment'

export default class RegistrationsTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      loading: true,
      forms: [],
      selectedForm: null,
    }

    this.table = React.createRef();
  }

  componentDidMount(){
    this._load()
  }

  _load(){
    Backend.getForms()
    .then(forms => {
      let selectedForm = null
      if(forms.length > 0){
        selectedForm = forms[forms.length - 1]
      }
      this.setState({
        forms,
        selectedForm,
        loading: false
      })
    })
    .catch(error => {
      Notify.error(error.message);
    })
  }

  _renderFormSelector(){
    let {
      forms,
      selectedForm
    } = this.state

    return (
      <AsyncSelect
        endpoint={window.Api.Forms}
        filter={`website_id=${AuthManager.currentWebsite.id}`}
        value={{
          value: selectedForm.id,
          label: selectedForm.name,
          data: selectedForm
        }}
        onSelected={form => {
          this.setState({ selectedForm: form })
        }}
        options={
          forms.map(form => ({
            value: form.id,
            label: form.name,
            data: form
          }))
        }
        getOptions={forms => {
          if(!this.state.selectedForm){
            this.setState({ selectedForm: forms[0] })
          }
          return forms.map(form => ({
            value: form.id,
            label: form.name,
            data: form
          })
        )}}
      />
    )
  }

  _getAnswer(field, submission){
    for(var i=0; i < submission.answers.length; i++){
      let answer = submission.answers[i]
      if(answer.field.id === field.id){
        return Submission.getAnswerValue(answer)
      }
    }

    return "-"
  }

  _getColumns() {
    let { selectedForm } = this.state

    let fields = []

    selectedForm.form_fields.forEach(row => {
      row.forEach(field => fields.push(field))
    })

    let fieldsToDisplayNum = selectedForm.form_fields.length > 5 ? 5 : selectedForm.form_fields.length

    let columns = [{
      Header: 'Date',
      id: 'created_at',
      accessor: submission => moment(submission.created_at).format('DD/MM/YYYY')
    }]

    for(var i=0; i < fieldsToDisplayNum; i++){
      let field = fields[i]
      console.log("ddd", field, fields)
      columns.push({
        Header: field.title,
        id: field.id.toString(),
        accessor: submission => {
          return this._getAnswer(field, submission)
        }
      })
    }

    columns.push({
      Header: 'Actions',
      id: 'actions',
      sortable: false,
      exportable: false,
      Cell: rowInfo => {
        let submission = rowInfo.original
        return (
          <SubmissionActionsCell
            submission={submission}
            onEditPressed={submission => this.setState({ selectedSubmission: submission, showSubmissionEditModal: true })}
          />
        )
      }
    })

    return columns
  }

  _renderSubmissionData(submission){

    let columns = this._getColumns()
    let unseenAnswers = []

    submission.answers.forEach(answer => {
      let seen = columns.findIndex(column => parseInt(column.id) === answer.field.id) > -1
      if(!seen){
        unseenAnswers.push(answer)
      }
    })
    return (
      <div style={{ background: "#f4f5fb" }}>
        <div style={{ margin: 20, padding: 20, background: "#FFF" }}>
          {
            unseenAnswers.map(answer => (
              <p style={{ margin: 0 }}>
                <span class="rt-resizable-header-content">{answer.field.title}:  </span>
                { Submission.getAnswerValue(answer) }
              </p>
            ))
          }
        </div>
      </div>
    )
  }

  render() {
    let {
      loading,
      endpoint,
      selectedForm,
      selectedSubmission,
      showSubmissionEditModal
    } = this.state

    if(loading || selectedForm == null){
      return null
    }

    const columns = this._getColumns()

    let params = {
      form_id: selectedForm.id,
      export: true,
      website_id: AuthManager.currentWebsite.id
    }

    return (
      <>
        <BaseTable
          ref={this.table}
          key={selectedForm.id}
          endpoint={window.Api.Submissions + "?form_id=" + selectedForm.id }
          title="Submissions"
          columns={columns}
          exportParams={params}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          SubComponent={row => this._renderSubmissionData(row.original)}
          renderHeaderRightContent={() => this._renderFormSelector()}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? 'visible' : 'hidden'
              }
            }
          }}
        />
        {
          showSubmissionEditModal &&
          <SubmissionModal
            show={showSubmissionEditModal}
            submissionId={selectedSubmission.id}
            onUpdated={() => {
              this.setState({selectedSubmission: null, showSubmissionEditModal: false})
              this.table.current.refresh()
            }}
            onHide={() => this.setState({selectedSubmission: null, showSubmissionEditModal: false})}
          />
        }
      </>
    )
  }
}
